





























import { PaginationQueryStringMixin } from '@/features/core/components/mixins/pagination-query-string';
import { Column } from '@/features/ui/table/model';
import plusIcon from '@/features/ui/assets/icons/plus.svg';
import { Component, Mixins } from 'vue-property-decorator';
import {
  AppAdminCustomersViewQuery,
  AppAdminCustomersViewQueryVariables,
} from './__generated__/AppAdminCustomersViewQuery';
import query from './view.gql';

@Component({
  apollo: {
    customers: {
      query,
      fetchPolicy: 'no-cache',
      variables(this: CustomersView): AppAdminCustomersViewQueryVariables {
        return { skip: this.skip, take: this.take };
      },
    },
  },
  data() {
    return { customers: undefined };
  },
})
export default class CustomersView extends Mixins(PaginationQueryStringMixin) {
  private readonly customers?: AppAdminCustomersViewQuery['customers'];
  private readonly plusIcon = plusIcon;

  private readonly columns: Column[] = [
    { name: 'name', label: 'Kunde' },
    { name: 'externalId', label: 'Kundennummer' },
    { name: 'businessContact', label: 'Geschäftskontakt' },
    { name: 'contracts', field: 'contracts.count', label: 'Verträge #', align: 'right' },
    { name: 'action', label: '', align: 'right' },
  ];

  protected get count(): number {
    return this.customers?.count ?? 0;
  }
}
