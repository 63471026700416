
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { RequiredProp } from '@/util/prop-decorators';
import { NodeEditorNode } from './model';

@Component
export default class NodeEditor extends Vue {
  @RequiredProp()
  protected node!: NodeEditorNode;

  public readonly classifications: string[] = ['Liegenschaft', 'Gebaude'];
}
