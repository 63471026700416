


























import { StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import { AppAdminUserViewQuery, AppAdminUserViewQueryVariables } from './__generated__/AppAdminUserViewQuery';
import query from './view.gql';

@Component({
  apollo: {
    users: {
      query,
      fetchPolicy: 'no-cache',
      variables(this: UserView): AppAdminUserViewQueryVariables {
        return { id: this.userId };
      },
    },
  },
  data() {
    return { user: undefined };
  },
})
export default class UserView extends Vue {
  @StringProp(true)
  private readonly userId!: string;

  private users!: AppAdminUserViewQuery['users'];
}
