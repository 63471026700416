
























import { PaginationQueryStringMixin } from '@/features/core/components/mixins/pagination-query-string';
import { Component, Mixins } from 'vue-property-decorator';
import {
  AppAdminAttributeDefinitionTemplatesViewQuery,
  AppAdminAttributeDefinitionTemplatesViewQueryVariables,
} from './__generated__/AppAdminAttributeDefinitionTemplatesViewQuery';
import query from './view.gql';
import { Action, RootAction } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';
import { isString } from 'lodash';

@Component({
  apollo: {
    attributeDefinitionTemplates: {
      query,
      fetchPolicy: 'cache-and-network',
      variables(this: AttributeDefinitionTemplatesView): AppAdminAttributeDefinitionTemplatesViewQueryVariables {
        return { searchQuery: this.searchQuery === '' ? null : this.searchQuery, skip: this.skip, take: this.take };
      },
      result(this: AttributeDefinitionTemplatesView): void {
        this.searchQuery = this.nextSearchQuery;
      },
    },
  },
  data() {
    return {
      attributeDefinitionTemplates: undefined,
      searchQuery: isString(this.$route.query.query) ? this.$route.query.query : '',
    };
  },
})
export default class AttributeDefinitionTemplatesView extends Mixins(PaginationQueryStringMixin) {
  private readonly attributeDefinitionTemplates?: AppAdminAttributeDefinitionTemplatesViewQuery['attributeDefinitionTemplates'];

  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  private searchQuery!: string;

  protected get count(): number {
    return this.attributeDefinitionTemplates?.count ?? 0;
  }

  private get templates(): AppAdminAttributeDefinitionTemplatesViewQuery['attributeDefinitionTemplates']['items'] {
    return this.attributeDefinitionTemplates?.items ?? [];
  }

  private get loading(): boolean {
    return this.$apollo.queries.attributeDefinitionTemplates.loading;
  }

  private get nextSearchQuery(): string {
    return isString(this.$route.query.query) ? this.$route.query.query : '';
  }

  private set nextSearchQuery(value: string) {
    this.$router.replace({ query: { ...this.$route.query, query: value === '' ? undefined : value } });

    if (!this.loading) {
      this.searchQuery = value;
    }
  }

  private onAttributeDefinitionTemplateAdded(attributeDefinitionTemplateId: string): void {
    this.$router.push({ name: 'AppAdmin/AttributeDefinitionTemplate', params: { attributeDefinitionTemplateId } });
    this.ADD_TOAST_MESSAGES({
      messages: [{ text: 'Attributvorlage erstellt.', class: 'success' }],
    });
  }
}
