













































import { RequiredProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import EditableValue from '@/components/editable-value/EditableValue.global.vue';
import { PropertySubdivisionVisualizationHint } from '@/types/iot-portal';
import { Floor } from './model';
import PropertySubdivisionEditor from './PropertySubdivisionEditor.vue';

@Component({
  components: { EditableValue, PropertySubdivisionEditor },
})
export default class PropertyEditorFloor extends Vue {
  @RequiredProp()
  private floor!: Floor;

  private dropdown = false;

  private get name(): string | null {
    return this.floor.name;
  }

  private set name(value: string | null) {
    this.floor.name = value;
    this.$emit('edit-floor');
  }

  private get canAddStairs(): boolean {
    return (
      [...(this.floor.left ?? []), ...(this.floor.right ?? [])].find(
        (propertySubdivision) =>
          propertySubdivision.visualizationHint === PropertySubdivisionVisualizationHint.STAIRWELL,
      ) === undefined
    );
  }

  private get canRemoveFloor(): boolean {
    return [...(this.floor.left ?? []), ...(this.floor.right ?? [])].length === 0;
  }

  private addApartment(): void {
    this.addPropertySubdivision(PropertySubdivisionVisualizationHint.APARTMENT);
    this.dropdown = false;
  }

  private addRoom(): void {
    this.addPropertySubdivision(PropertySubdivisionVisualizationHint.ROOM);
    this.dropdown = false;
  }

  private addStairs(): void {
    this.addPropertySubdivision(PropertySubdivisionVisualizationHint.STAIRWELL);
    this.dropdown = false;
  }

  private addPropertySubdivision(visualizationHint: PropertySubdivisionVisualizationHint): void {
    this.$emit('add-property-subdivision', visualizationHint);
  }

  private removePropertySubdivision(id: string): void {
    this.$emit('remove-property-subdivision', id);
  }

  private get hasPropertySubdivisions(): boolean {
    return (this.floor.left?.length ?? 0) + (this.floor.right?.length ?? 0) > 0;
  }

  private onDropdownOutsideClick(): void {
    if (this.dropdown) {
      this.dropdown = false;
    }
  }
}
