


































import { PaginationQueryStringMixin } from '@/features/core/components/mixins/pagination-query-string';
import plusIcon from '@/features/ui/assets/icons/plus.svg';
import { Column } from '@/features/ui/table/model';
import { isString } from 'lodash';
import { Component, Mixins } from 'vue-property-decorator';
import { AppAdminUsersViewQuery, AppAdminUsersViewQueryVariables } from './__generated__/AppAdminUsersViewQuery';
import query from './view.gql';
import configureUsersOTPMutation from './configure-users-otp.gql';
import {
  AppAdminUsersViewConfigureUsersOTPMutation,
  AppAdminUsersViewConfigureUsersOTPMutationVariables,
} from './__generated__/AppAdminUsersViewConfigureUsersOTPMutation';

@Component({
  apollo: {
    users: {
      query,
      fetchPolicy: 'no-cache',
      variables(this: UsersView): AppAdminUsersViewQueryVariables {
        return { query: this.searchQuery === '' ? null : this.searchQuery, skip: this.skip, take: this.take };
      },
      result(this: UsersView): void {
        this.searchQuery = this.nextSearchQuery;
      },
    },
  },
  data() {
    return { users: undefined, selectedUsers: [] };
  },
})
export default class UsersView extends Mixins(PaginationQueryStringMixin) {
  private readonly users?: AppAdminUsersViewQuery['users'];
  private searchQuery = '';

  private readonly plusIcon = plusIcon;
  private readonly columns: Column[] = [
    { name: 'email', label: 'E-Mail' },
    { name: 'name', label: 'Name' },
    { name: 'labels', label: 'Status' },
    { name: 'action', label: '', align: 'right' },
  ];

  private selectedUsers!: AppAdminUsersViewQuery['users']['items'];

  private async activateMFA(): Promise<void> {
    await this.$apollo.mutate<
      AppAdminUsersViewConfigureUsersOTPMutation,
      AppAdminUsersViewConfigureUsersOTPMutationVariables
    >({
      mutation: configureUsersOTPMutation,
      variables: { input: { userIds: this.selectedUsers.map(({ id }) => id), value: true } },
    });

    await this.$apollo.queries.users.refetch();
  }

  private async deactivateMFA(): Promise<void> {
    await this.$apollo.mutate<
      AppAdminUsersViewConfigureUsersOTPMutation,
      AppAdminUsersViewConfigureUsersOTPMutationVariables
    >({
      mutation: configureUsersOTPMutation,
      variables: { input: { userIds: this.selectedUsers.map(({ id }) => id), value: false } },
    });

    await this.$apollo.queries.users.refetch();
  }

  protected get count(): number {
    return this.users?.count ?? 0;
  }

  private get nextSearchQuery(): string {
    return isString(this.$route.query.query) ? this.$route.query.query : '';
  }

  private set nextSearchQuery(value: string) {
    this.$router.replace({ query: { ...this.$route.query, query: value === '' ? undefined : value } });
    if (!this.$apollo.queries.users.loading) {
      this.searchQuery = value;
    }
  }
}
