


















































































import ContainerMixin from '@/features/core/components/mixins/container';
import { Action, RootAction } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';
import { Option } from '@/features/ui/inputs/model';
import {
  SetUserGroupsInput,
  EditPhoneNumberInput,
  EditUserInput,
  EditUserTechnicianRoleInput,
  EditUserCustomerAdminRoleInput,
  SetRestrictedDeviceManufacturerPermissionsForUserInput,
  SpotRole,
  MFAState,
} from '@/types/iot-portal';
import { StringProp } from '@/util/prop-decorators';
import { Component, Mixins } from 'vue-property-decorator';
import editUserMutation from './edit-user.gql';
import groupsQuery from './groups.gql';
import manufacturersQuery from './manufacturers.gql';
import userDeviceRestrictedPermissionsQuery from './user-device-restricted-permissions.gql';
import query from './view.gql';
import {
  AppAdminUserGeneralViewEditUserMutation,
  AppAdminUserGeneralViewEditUserMutationVariables,
} from './__generated__/AppAdminUserGeneralViewEditUserMutation';
import {
  AppAdminUserGeneralViewQuery,
  AppAdminUserGeneralViewQueryVariables,
} from './__generated__/AppAdminUserGeneralViewQuery';
import { AppAdminUserGeneralViewGroupsQuery } from './__generated__/AppAdminUserGeneralViewGroupsQuery';
import { AppAdminUserGeneralViewManufacturersQuery } from './__generated__/AppAdminUserGeneralViewManufacturersQuery';
import {
  AppAdminUserGeneralViewDeviceRestrictedPermissionsQuery,
  AppAdminUserGeneralViewDeviceRestrictedPermissionsQueryVariables,
} from './__generated__/AppAdminUserGeneralViewDeviceRestrictedPermissionsQuery';
import ManufacturerMapMixin from '@/features/core/components/mixins/manufacturer-map';

@Component({
  apollo: {
    users: {
      query,
      fetchPolicy: 'no-cache',
      variables(this: GeneralView): AppAdminUserGeneralViewQueryVariables {
        return { id: this.userId };
      },
    },
    groups: {
      query: groupsQuery,
      fetchPolicy: 'no-cache',
    },
    manufacturers: {
      query: manufacturersQuery,
      fetchPolicy: 'no-cache',
    },
    userDeviceRestrictedPermissions: {
      query: userDeviceRestrictedPermissionsQuery,
      fetchPolicy: 'no-cache',
      variables(this: GeneralView): AppAdminUserGeneralViewDeviceRestrictedPermissionsQueryVariables {
        return { userId: this.userId };
      },
    },
  },
  data() {
    return { user: undefined, groups: undefined, manufacturers: undefined };
  },
})
export default class GeneralView extends Mixins(ContainerMixin, ManufacturerMapMixin) {
  @StringProp(true)
  private readonly userId!: string;

  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  private MFA_STATE = MFAState;

  private users?: AppAdminUserGeneralViewQuery['users'];

  private groups?: AppAdminUserGeneralViewGroupsQuery['groups'];

  private manufacturers?: AppAdminUserGeneralViewManufacturersQuery['manufacturers'];

  private userDeviceRestrictedPermissions?: AppAdminUserGeneralViewDeviceRestrictedPermissionsQuery['userDeviceRestrictedPermissions'];

  private get groupOptions(): Option[] {
    return this.groups?.items.map(({ id, name }) => ({ value: id, label: name })) ?? [];
  }

  private get manufacturersOptions(): Option[] {
    return (
      this.manufacturers?.manufacturerAggregations.map(({ manufacturer }) => ({
        value: manufacturer,
        label: manufacturer,
      })) ?? []
    );
  }

  private get deviceRoles(): Option[] {
    return Object.values(SpotRole).map((role) => ({
      label: role,
      value: role,
    }));
  }

  private async editUser({
    editUserInput,
    setUserGroupsInput,
    editPhoneNumberInput,
    setRestrictedDeviceManufacturerPermissionsForUserInput,
    editUserTechnicianRole: { technician },
    editUserCustomerAdminRoleInput: { customerAdmin },
    configureUserOTPInput,
  }: {
    editUserInput: EditUserInput;
    setUserGroupsInput: SetUserGroupsInput;
    editPhoneNumberInput: EditPhoneNumberInput;
    editUserTechnicianRole: EditUserTechnicianRoleInput;
    editUserCustomerAdminRoleInput: EditUserCustomerAdminRoleInput;
    setRestrictedDeviceManufacturerPermissionsForUserInput: SetRestrictedDeviceManufacturerPermissionsForUserInput;
    configureUserOTPInput: { mfa: MFAState.ACTIVE | MFAState.INACTIVE };
  }): Promise<void> {
    if (!this.users) {
      throw new Error('User not found');
    }

    await this.$apollo.mutate<
      AppAdminUserGeneralViewEditUserMutation,
      AppAdminUserGeneralViewEditUserMutationVariables
    >({
      mutation: editUserMutation,
      variables: {
        editUserInput: { ...editUserInput, id: this.users.first.id },
        setUserGroups: { ...setUserGroupsInput, userId: this.users.first.id },
        editPhoneNumberInput: { ...editPhoneNumberInput, id: this.users.first.id },
        editUserTechnicianRoleInput: {
          id: this.users.first.id,
          technician,
        },
        editUserCustomerAdminRoleInput: {
          id: this.users.first.id,
          customerAdmin,
        },
        setRestrictedDeviceManufacturerPermissionsForUserInput: {
          ...setRestrictedDeviceManufacturerPermissionsForUserInput,
          userId: this.users.first.id,
        },
        configureUserOTPInput: { userIds: [this.users.first.id], value: configureUserOTPInput.mfa === MFAState.ACTIVE },
      },
    });

    await this.$apollo.queries.users.refetch();

    this.ADD_TOAST_MESSAGES({
      messages: [{ text: 'Benutzer gespeichert!', class: 'success' }],
    });
  }

  private pluckIds<T>(objects: { id: T }[] | null): T[] | null {
    return objects?.map(({ id }) => id) ?? null;
  }
}
