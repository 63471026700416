




















import { Component, Mixins, Inject } from 'vue-property-decorator';
import UiClickable from '@/components/clickables/Clickable.global.vue';
import Node from './Node.vue';
import {
  NodeListNodeType,
  NODE_LIST_IMPORT_TO_DIRECTORY,
  NODE_LIST_ON_NODE_TOGGLE,
  NodeListImportToDirectory,
  NodeListOnNodeToggle,
} from './model';
import NodeMenu from './NodeMenu.vue';
import arrowUp from '@/features/app-admin/assets/arrow-up.svg';
import arrowDown from '@/features/app-admin/assets/arrow-down.svg';
import { v4 as uuidv4 } from 'uuid';

@Component({
  components: { UiClickable, NodeMenu },
  data() {
    return {
      arrowUp,
      arrowDown,
    };
  },
})
export default class DirectoryNode extends Mixins(Node) {
  private addChildNode(name: string, type: NodeListNodeType): void {
    this.addNode(this.node, {
      id: `temp_${uuidv4()}`,
      name,
      order: 0,
      floorsCount: 0,
      roomsAndApartmentsCount: 0,
      propertiesCount: 0,
      children: [],
      type,
      open: false,
    });
  }

  private onNodeClick(): void {
    this.onClick(this.node);
  }

  private onItemClick(index: number): void {
    switch (index) {
      case 0:
        this.editNode(this.node);
        break;
      case 1:
        this.importToDirectory(this.node);
        break;
      case 2:
        this.addChildNode('Neues Verzeichnis', 'Directory');
        break;
      case 3:
        this.addChildNode('Neues Gebäudegruppe', 'PropertyGroup');
        break;
      case 4:
        this.addChildNode('Neues Gebäude', 'Property');
        break;
      case 5:
        this.removeNode(this.parent, this.node);
        break;
    }
  }

  private get shouldShowToggle(): boolean {
    return this.node.children !== undefined && this.node.children.length > 0;
  }

  @Inject(NODE_LIST_IMPORT_TO_DIRECTORY)
  private importToDirectory!: NodeListImportToDirectory;

  @Inject(NODE_LIST_ON_NODE_TOGGLE)
  private onNodeToggle!: NodeListOnNodeToggle;
}
