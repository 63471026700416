var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.alertRules)?_c('ui-panel',[_c('ui-flex',[_c('ui-cell',{attrs:{"grow":0,"push-right":""}},[_c('ui-wizard',{attrs:{"title":"Alarmregelvorlage anlegen"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var show = ref.show;
return [_c('ui-wizard-button',{on:{"click":show}},[_vm._v("Vorlage anlegen")])]}},{key:"content",fn:function(ref){
var hide = ref.hide;
return [_c('ui-form',{attrs:{"action":_vm.addAlertRule},on:{"submitted":function($event){return _vm.onAlertRuleAdded($event.result, hide)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var dirty = ref.dirty;
var busy = ref.busy;
var submit = ref.submit;
var errorMessages = ref.errorMessages;
return [_c('core-toast',{attrs:{"messages":errorMessages,"error":""}}),_c('ui-flex',[_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-field',{attrs:{"input":"ui-input-text","path":"name","label":"Name"}})],1),_c('ui-cell',{attrs:{"grow":0,"push-right":""}},[_c('ui-wizard-button',{attrs:{"disabled":!dirty,"busy":busy},on:{"click":submit}},[_vm._v("Anlegen")])],1)],1)]}}],null,true)})]}}],null,false,1322426394)})],1),(_vm.totalPages > 0)?_c('ui-cell',{attrs:{"basis":1}},[_c('ui-table',{attrs:{"columns":_vm.columns,"rows":_vm.alertRules.items},scopedSlots:_vm._u([{key:"name.label",fn:function(){return [_vm._v("Name"),_c('br'),_vm._v("Beschreibung")]},proxy:true},{key:"name",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"text-overflow"},[_vm._v(_vm._s(row.name)),_c('br'),_c('span',{staticClass:"text-light"},[_vm._v(_vm._s(row.description || '–'))])])]}},{key:"type.label",fn:function(){return [_vm._v("Typ"),_c('br'),_vm._v("Anwendbar auf")]},proxy:true},{key:"type",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"text-overflow"},[_c('domain-ui-alert-rule-strategy-type',{attrs:{"type":row.strategy.__typename,"inline":""}}),_c('br'),_c('ui-inline-list',{staticClass:"text-light",attrs:{"items":_vm.applicableSpotRoleListOf(row)}})],1)]}},{key:"enabled.label",fn:function(){return [_vm._v("Aktiviert"),_c('br'),_vm._v("Freigabe")]},proxy:true},{key:"enabled",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.enabled ? 'Ja' : 'Nein')),_c('br'),(row.selectable === false)?[_c('span',{staticClass:"text-light"},[_vm._v("Gesperrt")])]:(row.allowedCustomers === null)?[_c('span',{staticClass:"text-light"},[_vm._v("Unbeschränkt")])]:[_c('ui-tooltip',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('em',{staticClass:"text-light"},[_vm._v("Eingeschränkt*")])]},proxy:true},{key:"content",fn:function(){return _vm._l((row.allowedCustomers),function(customer){return _c('div',{key:customer.id},[_vm._v(_vm._s(customer.name))])})},proxy:true}],null,true)})]]}},{key:"action",fn:function(ref){
var row = ref.row;
return [_c('ui-link',{on:{"click":function($event){return _vm.$router.push({ name: 'AppAdmin/AlertRule', params: { alertRuleId: row.id } })}}},[_vm._v("Bearbeiten")])]}}],null,false,1460380689)}),_c('ui-pagination',{attrs:{"total-pages":_vm.totalPages},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1):_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-message',[_vm._v("Keine Alarmregelvorlagen definiert.")])],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }