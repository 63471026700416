var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-panel',[_c('ui-flex',[_c('ui-cell',{attrs:{"max":250}},[_c('ui-input-text',{attrs:{"placeholder":"Suchen"},model:{value:(_vm.nextSearchQuery),callback:function ($$v) {_vm.nextSearchQuery=$$v},expression:"nextSearchQuery"}})],1),_c('ui-cell',{attrs:{"grow":0,"push-right":""}},[_c('app-admin-add-user-wizard-control',{on:{"user-added":function($event){return _vm.$router.push({ name: 'AppAdmin/User', params: { userId: $event } })}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var show = ref.show;
return [_c('ui-button',{attrs:{"front":"","icon":_vm.plusIcon},on:{"click":show}},[_vm._v("Benutzer anlegen")])]}}])})],1)],1),_c('ui-flex',{attrs:{"justify":"flex-end"}},[_c('ui-cell',{attrs:{"grow":0}},[_c('ui-wizard-button',{attrs:{"disabled":_vm.selectedUsers.length === 0},on:{"click":_vm.activateMFA}},[_vm._v("MFA aktivieren")])],1),_c('ui-cell',{attrs:{"grow":0}},[_c('ui-wizard-button',{attrs:{"disabled":_vm.selectedUsers.length === 0},on:{"click":_vm.deactivateMFA}},[_vm._v("MFA deaktivieren")])],1)],1),_c('ui-flex',[_c('ui-cell',{attrs:{"basis":1}},[_c('ui-table-skeleton',{attrs:{"ready":_vm.users && !_vm.$apollo.queries.users.loading,"column-count":4},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('ui-table',{attrs:{"columns":_vm.columns,"rows":_vm.users.items,"selected-rows":_vm.selectedUsers,"selectable":""},on:{"update:selectedRows":function($event){_vm.selectedUsers=$event},"update:selected-rows":function($event){_vm.selectedUsers=$event}},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.name))]}},{key:"labels",fn:function(ref){
var row = ref.row;
return [_c('app-admin-user-labels',{attrs:{"user":row}})]}},{key:"action",fn:function(ref){
var row = ref.row;
return [_c('ui-link',{attrs:{"to":{ name: 'AppAdmin/User', params: { userId: row.id } }}},[_vm._v("Bearbeiten")])]}}])})]},proxy:true}])})],1),(_vm.totalPages > 1)?_c('ui-cell',{attrs:{"grow":0,"push-right":""}},[_c('ui-pagination',{attrs:{"total-pages":_vm.totalPages},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }