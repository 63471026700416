


























import { Component, Vue } from 'vue-property-decorator';
import moment from 'moment';

@Component
export default class LatestMetricsAlertRuleStrategy extends Vue {
  private transformDuration(value: string | null): string | null {
    if (value === null) {
      return null;
    }

    const duration = moment.duration(value);
    if (duration.asMilliseconds() < 1) {
      return null;
    }

    return duration.toISOString();
  }
}
