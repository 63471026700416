





















import { ObjectProp } from '@/util/prop-decorators';
import { AddContractInput } from '@/types/iot-portal';
import { Component, Vue } from 'vue-property-decorator';
import {
  AppAdminAddContractMutation,
  AppAdminAddContractMutationVariables,
} from './__generated__/AppAdminAddContractMutation';
import addContractMutation from './add-contract.gql';

type Contract = AppAdminAddContractMutation['addContract']['contract'];

@Component
export default class AddContractWizardControl extends Vue {
  @ObjectProp()
  private readonly initialData?: Partial<AddContractInput>;

  public async addContract(input: AddContractInput): Promise<Contract> {
    const { data } = await this.$apollo.mutate<AppAdminAddContractMutation, AppAdminAddContractMutationVariables>({
      mutation: addContractMutation,
      variables: { input: { ...this.initialData, ...input } },
    });

    if (!data) {
      throw new Error('Der Vertrag konnte nicht hinzugefügt werden!');
    }

    return data.addContract.contract;
  }

  private async onSubmitted(contract: Contract, hide: () => Promise<void>): Promise<void> {
    await hide();

    this.$emit('contract-added', contract);
  }
}
