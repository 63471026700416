









import { RootAction, Action } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';
import { StringProp } from '@/util/prop-decorators';
import { EditContractInput } from '@/types/iot-portal';
import { Component, Vue } from 'vue-property-decorator';
import {
  AppAdminContractGeneralViewEditContractMutation,
  AppAdminContractGeneralViewEditContractMutationVariables,
} from './__generated__/AppAdminContractGeneralViewEditContractMutation';
import {
  AppAdminContractGeneralViewQuery,
  AppAdminContractGeneralViewQueryVariables,
} from './__generated__/AppAdminContractGeneralViewQuery';
import editContractMutation from './edit-contract.gql';
import query from './view.gql';

@Component({
  apollo: {
    contract: {
      query,
      fetchPolicy: 'no-cache',
      variables(this: GeneralView): AppAdminContractGeneralViewQueryVariables {
        return { id: this.contractId };
      },
    },
  },
  data() {
    return { contract: undefined };
  },
})
export default class GeneralView extends Vue {
  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  @StringProp(true)
  private readonly contractId!: string;

  private readonly contract?: AppAdminContractGeneralViewQuery['contract'];

  private async editContract(input: EditContractInput): Promise<void> {
    await this.$apollo.mutate<
      AppAdminContractGeneralViewEditContractMutation,
      AppAdminContractGeneralViewEditContractMutationVariables
    >({
      mutation: editContractMutation,
      variables: { input },
    });

    this.ADD_TOAST_MESSAGES({
      messages: [{ text: 'Vertrag gespeichert!', class: 'success' }],
    });
  }
}
