




























import { ObjectProp } from '@/util/prop-decorators';
import { AddUserInput } from '@/types/iot-portal';
import { Component, Vue } from 'vue-property-decorator';
import { AppAdminAddUserMutation, AppAdminAddUserMutationVariables } from './__generated__/AppAdminAddUserMutation';
import addUserMutation from './add-user.gql';

@Component
export default class AddUserWizardControl extends Vue {
  @ObjectProp()
  private initialData?: Partial<AddUserInput>;

  private async addUser({ input, repeatedEmail }: { input: AddUserInput; repeatedEmail: string }): Promise<string> {
    if (input.email !== repeatedEmail) {
      throw new Error('E-Mail-Adressen stimmen nicht überein!');
    }

    const { data } = await this.$apollo.mutate<AppAdminAddUserMutation, AppAdminAddUserMutationVariables>({
      mutation: addUserMutation,
      variables: { input },
    });

    if (!data) {
      throw new Error('Der Benutzer konnte nicht hinzugefügt werden!');
    }

    return data.addUser.id;
  }
}
