export type NodeListNodeType = 'Directory' | 'Property' | 'PropertyGroup';

export interface NodeListNode {
  id: string;
  name: string;
  order: number;
  type: NodeListNodeType;
  children?: NodeListNode[];
  floorsCount?: number;
  roomsAndApartmentsCount?: number;
  propertiesCount?: number;
  open?: boolean;
}
export const NODE_LIST_ADD_NODE = Symbol('NODE_LIST_ADD_NODE');
export const NODE_LIST_REMOVE_NODE = Symbol('NODE_LIST_REMOVE_NODE');
export const NODE_LIST_ON_NODE_CLICK = Symbol('NODE_LIST_ON_NODE_CLICK');
export const NODE_LIST_IMPORT_TO_DIRECTORY = Symbol('NODE_LIST_IMPORT_TO_DIRECTORY');
export const NODE_LIST_EDIT_NODE = Symbol('NODE_LIST_EDIT_NODE');
export const NODE_LIST_ON_NODE_TOGGLE = Symbol('NODE_LIST_ON_NODE_TOGGLE');
export const NODE_LIST_EDIT_NODE_ORDER = Symbol('NODE_LIST_EDIT_NODE_ORDER');

export type NodeListAddNode = (parentNode: NodeListNode | undefined, childNode: NodeListNode) => void;
export type NodeListRemoveNode = (parentNode: NodeListNode | undefined, childNode: NodeListNode) => void;
export type NodeListOnNodeClick = (childNode: NodeListNode) => void;
export type NodeListEditNode = (childNode: NodeListNode) => void;
export type NodeListOnNodeToggle = (node: NodeListNode) => void;
export type NodeListEditNodeOrder = (node: NodeListNode, order: number) => void;

export interface NodeListProvider {
  nodeListAddNode: NodeListAddNode;
  nodeListRemoveNode: NodeListRemoveNode;
  nodeListOnNodeClick: NodeListOnNodeClick;
  nodeListEditNode: NodeListEditNode;
  nodeListOnNodeToggle: NodeListOnNodeToggle;
  nodeListEditNodeOrder: NodeListEditNodeOrder;
}

export type NodeListImportToDirectory = (directory: NodeListNode) => void;
