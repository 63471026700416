






























































































































import { Action, RootAction } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';
import { ATTRIBUTE_CONFIGURATION_TYPE_META } from '@/features/domain-ui/attribute-configuration-type/constants';
import { TREE_NODE_TYPE_META } from '@/features/domain-ui/tree-node-type/constants';
import { Option } from '@/features/ui/inputs/model';
import {
  AddTemplateBasedAttributeDefinitionsInput,
  AttributeConfigurationType,
  EditAttributeDefinitionTemplateInput,
  TreeNodeType,
} from '@/types/iot-portal';
import { StringProp } from '@/util/prop-decorators';
import { ApolloQueryResult } from '@@/node_modules/apollo-client';
import { Component, Vue } from 'vue-property-decorator';
import addTemplateBasedAttributeDefinitionsMutation from './add-template-based-attribute-definitions.gql';
import editAttributeDefinitionTemplateMutation from './edit-attribute-definition-template.gql';
import removeAttributeDefinitionTemplateMutation from './remove-attribute-definition-template.gql';
import query from './view.gql';
import {
  AppAdminAttributeDefinitionTemplateViewAddTemplateBasedAttributeDefinitionsMutation,
  AppAdminAttributeDefinitionTemplateViewAddTemplateBasedAttributeDefinitionsMutationVariables,
} from './__generated__/AppAdminAttributeDefinitionTemplateViewAddTemplateBasedAttributeDefinitionsMutation';
import {
  AppAdminAttributeDefinitionTemplateViewEditAttributeDefinitionTemplateMutation,
  AppAdminAttributeDefinitionTemplateViewEditAttributeDefinitionTemplateMutationVariables,
} from './__generated__/AppAdminAttributeDefinitionTemplateViewEditAttributeDefinitionTemplateMutation';
import {
  AppAdminAttributeDefinitionTemplateViewQuery,
  AppAdminAttributeDefinitionTemplateViewQueryVariables,
} from './__generated__/AppAdminAttributeDefinitionTemplateViewQuery';
import {
  AppAdminAttributeDefinitionTemplateViewRemoveAttributeDefinitionTemplateMutation,
  AppAdminAttributeDefinitionTemplateViewRemoveAttributeDefinitionTemplateMutationVariables,
} from './__generated__/AppAdminAttributeDefinitionTemplateViewRemoveAttributeDefinitionTemplateMutation';

@Component({
  apollo: {
    view: {
      query,
      variables(this: AttributeDefinitionTemplateView): AppAdminAttributeDefinitionTemplateViewQueryVariables {
        return { id: this.attributeDefinitionTemplateId };
      },
      manual: true,
      result(
        this: AttributeDefinitionTemplateView,
        { data }: ApolloQueryResult<AppAdminAttributeDefinitionTemplateViewQuery>,
      ): void {
        this.attributeDefinitionTemplates = data.attributeDefinitionTemplates;
        this.customers = data.customers;
      },
    },
  },
  data() {
    return { attributeDefinitionTemplates: undefined, emptyArray: [] };
  },
})
export default class AttributeDefinitionTemplateView extends Vue {
  @StringProp(true)
  private readonly attributeDefinitionTemplateId!: string;

  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  private attributeDefinitionTemplates?: AppAdminAttributeDefinitionTemplateViewQuery['attributeDefinitionTemplates'];
  private customers?: AppAdminAttributeDefinitionTemplateViewQuery['customers'];

  private get attributeTypeOptions(): Option<AttributeConfigurationType>[] {
    return Object.values(ATTRIBUTE_CONFIGURATION_TYPE_META).sort((a, b) => a.label.localeCompare(b.label));
  }

  private get customerOptions(): Option[] {
    return this.customers?.items.map(({ id, name }) => ({ value: id, label: name })) ?? [];
  }

  private get treeNodeTypeOptions(): Option<TreeNodeType>[] {
    return Object.values(TREE_NODE_TYPE_META)
      .map(({ value, labelPlural }) => ({ value, label: labelPlural }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  private async editAttributeDefinitionTemplate(input: EditAttributeDefinitionTemplateInput): Promise<void> {
    const { data } = await this.$apollo.mutate<
      AppAdminAttributeDefinitionTemplateViewEditAttributeDefinitionTemplateMutation,
      AppAdminAttributeDefinitionTemplateViewEditAttributeDefinitionTemplateMutationVariables
    >({
      mutation: editAttributeDefinitionTemplateMutation,
      variables: { input: { ...input, id: this.attributeDefinitionTemplateId } },
    });

    if (!data) {
      throw new Error('Attributvorlage konnte nicht gespeichert werden.');
    }

    this.ADD_TOAST_MESSAGES({
      messages: [{ text: 'Attributvorlage gespeichert!', class: 'success' }],
    });
  }

  private async removeAttributeDefinitionTemplate(): Promise<void> {
    if (!window.confirm(`Sind Sie sich sicher, dass Sie diese Vorlage löschen möchten?`)) {
      return;
    }

    const { data } = await this.$apollo.mutate<
      AppAdminAttributeDefinitionTemplateViewRemoveAttributeDefinitionTemplateMutation,
      AppAdminAttributeDefinitionTemplateViewRemoveAttributeDefinitionTemplateMutationVariables
    >({
      mutation: removeAttributeDefinitionTemplateMutation,
      variables: { input: { id: this.attributeDefinitionTemplateId } },
    });

    if (!data) {
      throw new Error('Attributvorlage konnte nicht gelöscht werden.');
    }
  }

  private async addTemplateBasedAttributeDefinitions(
    input: Omit<AddTemplateBasedAttributeDefinitionsInput, 'templateId'>,
  ): Promise<number> {
    const { data } = await this.$apollo.mutate<
      AppAdminAttributeDefinitionTemplateViewAddTemplateBasedAttributeDefinitionsMutation,
      AppAdminAttributeDefinitionTemplateViewAddTemplateBasedAttributeDefinitionsMutationVariables
    >({
      mutation: addTemplateBasedAttributeDefinitionsMutation,
      variables: { input: { ...input, templateId: this.attributeDefinitionTemplateId } },
    });

    if (!data) {
      throw new Error('Attribute konnten nicht erzeugt werden.');
    }

    return data.addTemplateBasedAttributeDefinitions.attributeDefinitions.length;
  }

  private async onAttributeDefinitionTemplateRemoved(): Promise<void> {
    this.ADD_TOAST_MESSAGES({
      messages: [{ text: 'Attributvorlage gelöscht!', class: 'success' }],
    });

    this.$router.back();
  }

  private async onAttributeDefinitionsAdded(count: number, hide: () => Promise<void>): Promise<void> {
    await hide();

    if (count > 0) {
      this.ADD_TOAST_MESSAGES({
        messages: [{ text: `Attribute für ${count} Kunden erzeugt.`, class: 'success' }],
      });
    } else {
      this.ADD_TOAST_MESSAGES({
        messages: [{ text: 'Alle ausgewählten Kunden besitzen bereits ein Attribut mit diesem Namen.', class: 'info' }],
      });
    }
  }
}
