









import { RootState, IdToken } from '@/features/core/store';
import { MFAState } from '@/types/iot-portal';
import { ObjectProp, BooleanProp } from '@/util/prop-decorators';
import { Vue, Component } from 'vue-property-decorator';
import { AppAdminUserLabelsFragment } from './__generated__/AppAdminUserLabelsFragment';

interface Label {
  label: string;
  color: string;
}

@Component
export default class UserLabels extends Vue {
  @ObjectProp()
  private readonly user?: AppAdminUserLabelsFragment;

  @BooleanProp()
  private readonly leadingSpace!: boolean;

  @RootState
  private readonly idToken?: IdToken;

  private *labels(): IterableIterator<Label> {
    if (!this.user) {
      return;
    }

    if (this.user.admin) {
      yield { label: 'Admin', color: 'green' };
    }
    if (this.user.technician) {
      yield { label: 'Technician', color: 'green' };
    }
    if (!this.user.emailVerified) {
      yield { label: 'Nicht Verifiziert', color: 'orange' };
    }
    if (!this.user.enabled) {
      yield { label: 'Deaktiviert', color: 'red' };
    }
    if (this.idToken?.sub === this.user.id) {
      yield { label: 'Sie', color: 'blue' };
    }
    if (this.user.mfa === MFAState.ACTIVE) {
      yield { label: 'MFA aktiv', color: 'green' };
    }
    if (this.user.mfa === MFAState.INACTIVE) {
      yield { label: 'MFA inaktiv', color: 'orange' };
    }
    if (this.user.mfa === MFAState.CONFIGURING) {
      yield { label: 'MFA konfigurieren', color: 'grey' };
    }
  }
}
