




















import { BooleanProp, ArrayProp } from '@/util/prop-decorators';
import { Vue, Component, Model, Watch } from 'vue-property-decorator';
import { AppAdminPropertySelectNodeFragment } from './__generated__/AppAdminPropertySelectNodeFragment';
import {
  AppAdminPropertySelectQuery,
  AppAdminPropertySelectQueryVariables,
} from './__generated__/AppAdminPropertySelectQuery';
import query from './property-select.gql';

@Component({
  apollo: {
    searchTreeNodes: {
      query,
      fetchPolicy: 'no-cache',
      skip(this: PropertySelectControl): boolean {
        return this.searchQuery === '';
      },
      variables(this: PropertySelectControl): AppAdminPropertySelectQueryVariables {
        return { searchQuery: this.searchQuery, ancestorIds: this.ancestorIds };
      },
      result(this: PropertySelectControl): void {
        this.searchQuery = this.nextSearchQuery;
      },
    },
  },
  data() {
    return { searchTreeNodes: undefined };
  },
})
export default class PropertySelectControl extends Vue {
  @Model('update')
  private readonly value?: AppAdminPropertySelectNodeFragment;

  @ArrayProp()
  private readonly ancestorIds?: string[];

  @BooleanProp()
  private readonly disabled!: boolean;

  private readonly searchTreeNodes?: AppAdminPropertySelectQuery['searchTreeNodes'];

  private searchQuery = '';
  private nextSearchQuery = '';

  private get options(): AppAdminPropertySelectNodeFragment[] {
    return this.searchTreeNodes?.items.map(({ treeNode }) => treeNode as AppAdminPropertySelectNodeFragment) ?? [];
  }

  @Watch('nextSearchQuery')
  private search(): void {
    if (this.$apollo.queries.searchTreeNodes.loading) {
      return;
    }

    this.searchQuery = this.nextSearchQuery;
  }
}
