
















































































import { Action, RootAction } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';
import Busyable, { Busy } from '@/features/ui/mixins/busyable';
import { StringProp } from '@/util/prop-decorators';
import { ApolloQueryResult } from '@@/node_modules/apollo-client';
import { difference, groupBy } from 'lodash';
import { Component, Mixins } from 'vue-property-decorator';
import customersQuery from './customers.gql';
import setPermissionsForUserMutation from './set-permissions-for-user.gql';
import treeNodesQuery from './tree-nodes.gql';
import query from './view.gql';
import { AppAdminUserPermissionsViewCustomersQuery } from './__generated__/AppAdminUserPermissionsViewCustomersQuery';
import {
  AppAdminUserPermissionsViewQuery,
  AppAdminUserPermissionsViewQueryVariables,
} from './__generated__/AppAdminUserPermissionsViewQuery';
import {
  AppAdminUserPermissionsViewSetPermissionsForUserMutation,
  AppAdminUserPermissionsViewSetPermissionsForUserMutationVariables,
} from './__generated__/AppAdminUserPermissionsViewSetPermissionsForUserMutation';
import {
  AppAdminUserPermissionsViewTreeNodesQuery,
  AppAdminUserPermissionsViewTreeNodesQueryVariables,
} from './__generated__/AppAdminUserPermissionsViewTreeNodesQuery';

type TreeNodePermissionsData = {
  id: string;
  name: string;
  scopes: string[];
};

type CustomerPermissionsData = {
  id: string;
  name: string;
  scopes: string[];
  treeNodePermissions: TreeNodePermissionsData[];
};

type CustomerPermissions = CustomerPermissionsData[];

type Customer = AppAdminUserPermissionsViewCustomersQuery['customers']['items'][number];

export enum SCOPE {
  TREE_NODE_VIEW = 'https://ns.smartwowi.de/portal/authz/scopes/tree-node-view',
  TREE_NODE_WRITE = 'https://ns.smartwowi.de/portal/authz/scopes/tree-node-write',
  CUSTOMER_READ = 'https://ns.smartwowi.de/portal/authz/scopes/customer-read',
  CUSTOMER_WRITE = 'https://ns.smartwowi.de/portal/authz/scopes/customer-write',
}

export const RESOURCE_TYPE_TREE_NODE = 'https://ns.smartwowi.de/portal/authz/resource-types/tree-node';
export const RESOURCE_TYPE_CUSTOMER = 'https://ns.smartwowi.de/portal/authz/resource-types/customer';

function findTreeNodePermissionsData(
  customer: CustomerPermissionsData,
  treeNodeId: string,
): TreeNodePermissionsData | undefined {
  return customer.treeNodePermissions.find(({ id }) => treeNodeId === id);
}

@Component({
  apollo: {
    users: {
      query,
      fetchPolicy: 'network-only',
      variables(this: PermissionsView): AppAdminUserPermissionsViewQueryVariables {
        return { id: this.userId };
      },
      result(this: PermissionsView, { data }: ApolloQueryResult<AppAdminUserPermissionsViewQuery>): void {
        this.treeNodeIds = data.users.first.permissions
          .filter(({ resourceType }) => resourceType === RESOURCE_TYPE_TREE_NODE)
          .map(({ resource }) => resource);
      },
    },
    customers: {
      query: customersQuery,
      fetchPolicy: 'no-cache',
    },
    treeNodes: {
      query: treeNodesQuery,
      fetchPolicy: 'network-only',
      skip(this: PermissionsView): boolean {
        return !this.treeNodeIds || !this.customers;
      },
      variables(this: PermissionsView): AppAdminUserPermissionsViewTreeNodesQueryVariables {
        return { ids: this.treeNodeIds };
      },
      result(this: PermissionsView, { data }: ApolloQueryResult<AppAdminUserPermissionsViewTreeNodesQuery>): void {
        const treeNodesByCustomerId = groupBy(data.treeNodes.items, 'customer.id');
        this.customerPermissions = Object.entries(treeNodesByCustomerId).map(([customerId, customerTreeNodes]) => ({
          id: customerId,
          name: this.customers?.items.find(({ id }) => id === customerId)?.name ?? '',
          scopes:
            (this.users as NonNullable<typeof this.users>).first.permissions.find(
              ({ resource, resourceType }) => resource === customerId && resourceType === RESOURCE_TYPE_CUSTOMER,
            )?.scopes ?? [],
          treeNodePermissions: customerTreeNodes.map(({ id, name }) => ({
            id,
            name,
            scopes:
              (this.users as NonNullable<typeof this.users>).first.permissions.find(
                ({ resource, resourceType }) => resource === id && resourceType === RESOURCE_TYPE_TREE_NODE,
              )?.scopes ?? [],
          })),
        }));
      },
    },
  },
  data() {
    return {
      users: undefined,
      customers: undefined,
      customerPermissions: undefined,
      dirty: false,
      treeNodes: undefined,
      treeNodeIds: undefined,
      findTreeNodePermissionsData,
    };
  },
})
export default class PermissionsView extends Mixins(Busyable) {
  @StringProp(true)
  private readonly userId!: string;

  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  private readonly users?: AppAdminUserPermissionsViewQuery['users'];

  private readonly customers?: AppAdminUserPermissionsViewCustomersQuery['customers'];

  private readonly treeNodes?: AppAdminUserPermissionsViewTreeNodesQuery['treeNodes'];

  private customerPermissions?: CustomerPermissions;

  private dirty!: boolean;

  private treeNodeIds?: string[];

  private get availableCustomers(): Customer[] {
    return this.customers?.items.filter(({ id }) => !this.customerPermissions?.map(({ id }) => id).includes(id)) ?? [];
  }

  private get flatPermissions(): { resource: string; resourceType: string; scopes: string[] }[] {
    return [
      ...(this.customerPermissions?.map(({ id, scopes }) => ({
        resource: id,
        scopes,
        resourceType: RESOURCE_TYPE_CUSTOMER,
      })) ?? []),
      ...(this.customerPermissions?.map(({ treeNodePermissions }) =>
        treeNodePermissions.map(({ id, scopes }) => ({ resource: id, scopes, resourceType: RESOURCE_TYPE_TREE_NODE })),
      ) ?? []),
    ].flat();
  }

  private get sortedCustomerPermissions(): CustomerPermissions {
    return this.customerPermissions?.sort((a, b) => a.name.localeCompare(b.name)) ?? [];
  }

  private getRootIds(customerPermissionsData: CustomerPermissionsData): string[] {
    const customer = this.customers?.items.find(({ id }) => id === customerPermissionsData.id);
    if (!customer) {
      return [];
    }

    return [customer.rootDirectory.id];
  }

  private addCustomer(customer: Customer): void {
    if (!this.customerPermissions) {
      return;
    }
    this.dirty = true;
    this.customerPermissions.push({
      name: customer.name,
      id: customer.id,
      scopes: [],
      treeNodePermissions: [],
    });
  }

  private removeCustomerPermissions(customer: CustomerPermissionsData): void {
    if (!this.customerPermissions) {
      return;
    }
    this.dirty = true;
    this.customerPermissions = this.customerPermissions.filter((currentCustomer) => currentCustomer !== customer);
  }

  @Busy()
  private async setPermissionsForUser(): Promise<void> {
    if (!this.users) {
      return;
    }

    const permissions = [
      ...this.flatPermissions,
      ...this.users.first.permissions.filter(
        ({ resourceType }) => resourceType !== RESOURCE_TYPE_TREE_NODE && resourceType !== RESOURCE_TYPE_CUSTOMER,
      ),
    ];

    try {
      const { data } = await this.$apollo.mutate<
        AppAdminUserPermissionsViewSetPermissionsForUserMutation,
        AppAdminUserPermissionsViewSetPermissionsForUserMutationVariables
      >({
        mutation: setPermissionsForUserMutation,
        variables: { input: { user: this.userId, permissions } },
      });

      if (!data) {
        throw new Error('Die Zugriffsrechte für den Nutzer konnten nicht geändert werden.');
      }

      this.ADD_TOAST_MESSAGES({
        messages: [{ text: 'Zugriffsrechte gespeichert!', class: 'success' }],
      });
    } catch (e) {
      this.ADD_TOAST_MESSAGES({
        messages: [{ text: 'Die Zugriffsrechte für den Nutzer konnten nicht geändert werden.', class: 'error' }],
      });
    }

    this.dirty = false;
  }

  private pluckId<T>(objects: { id: T }[]): T[] {
    return objects?.map(({ id }) => id);
  }

  private onTreeNodeSelectUpdate(customer: CustomerPermissionsData, treeNodes: { id: string; name: string }[]): void {
    this.dirty = true;
    const newTreeNodeIds = this.pluckId(treeNodes);
    const existingTreeNodeIds = this.pluckId(customer.treeNodePermissions);
    const addedTreeNodeIds = difference(newTreeNodeIds, existingTreeNodeIds);
    const removedTreeNodeIds = difference(existingTreeNodeIds, newTreeNodeIds);
    customer.treeNodePermissions = customer.treeNodePermissions
      .concat(
        addedTreeNodeIds.map((treeNodeId) => ({
          ...(treeNodes.find(({ id }) => id === treeNodeId) as NonNullable<typeof treeNodes[0]>),
          scopes: [SCOPE.TREE_NODE_VIEW],
        })),
      )
      .filter(({ id }) => !removedTreeNodeIds.includes(id))
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  private hasCustomerReadScope(scopes: string[]): boolean {
    return scopes.includes(SCOPE.CUSTOMER_READ);
  }

  private hasCustomerWriteScope(scopes: string[]): boolean {
    return scopes.includes(SCOPE.CUSTOMER_WRITE);
  }

  private onCustomerReadScopeChange(customer: CustomerPermissionsData): void {
    this.dirty = true;
    if (this.hasCustomerReadScope(customer.scopes)) {
      customer.scopes = [];
    } else {
      customer.scopes.push(SCOPE.CUSTOMER_READ);
    }
  }

  private onCustomerWriteScopeChange(customer: CustomerPermissionsData): void {
    this.dirty = true;
    if (this.hasCustomerWriteScope(customer.scopes)) {
      customer.scopes = [SCOPE.CUSTOMER_READ];
    } else {
      customer.scopes = [SCOPE.CUSTOMER_READ, SCOPE.CUSTOMER_WRITE];
    }
  }

  private hasTreeNodeViewScope(scopes: string[]): boolean {
    return scopes.includes(SCOPE.TREE_NODE_VIEW);
  }

  private hasTreeNodeWriteScope(scopes: string[]): boolean {
    return scopes.includes(SCOPE.TREE_NODE_WRITE);
  }

  private onTreeNodeWriteScopeChange(treeNode: TreeNodePermissionsData): void {
    this.dirty = true;
    if (this.hasTreeNodeWriteScope(treeNode.scopes)) {
      treeNode.scopes = [SCOPE.TREE_NODE_VIEW];
    } else {
      treeNode.scopes = [SCOPE.TREE_NODE_VIEW, SCOPE.TREE_NODE_WRITE];
    }
  }
}
