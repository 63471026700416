























import { ObjectProp } from '@/util/prop-decorators';
import { AddCustomerInput } from '@/types/iot-portal';
import { Component, Vue } from 'vue-property-decorator';
import {
  AppAdminAddCustomerMutation,
  AppAdminAddCustomerMutationVariables,
} from './__generated__/AppAdminAddCustomerMutation';
import addCustomerMutation from './add-customer.gql';

type Customer = AppAdminAddCustomerMutation['addCustomer']['customer'];

@Component
export default class AddCustomerWizardControl extends Vue {
  @ObjectProp()
  private readonly initialData?: Partial<AddCustomerInput>;

  private async addCustomer(input: AddCustomerInput): Promise<Customer> {
    const { data } = await this.$apollo.mutate<AppAdminAddCustomerMutation, AppAdminAddCustomerMutationVariables>({
      mutation: addCustomerMutation,
      variables: { input: { ...this.initialData, ...input } },
    });

    if (!data) {
      throw new Error('Der Kunde konnte nicht hinzugefügt werden!');
    }

    return data.addCustomer.customer;
  }

  private async onSubmitted(customer: Customer, hide: () => Promise<void>): Promise<void> {
    await hide();

    this.$emit('customer-added', customer);
  }
}
