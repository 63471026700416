























import { PaginationQueryStringMixin } from '@/features/core/components/mixins/pagination-query-string';
import { StringProp } from '@/util/prop-decorators';
import { Component, Mixins } from 'vue-property-decorator';
import {
  AppAdminCustomerExportPresetsViewQuery,
  AppAdminCustomerExportPresetsViewQueryVariables,
} from './__generated__/AppAdminCustomerExportPresetsViewQuery';
import query from './view.gql';

@Component({
  apollo: {
    customer: {
      query,
      fetchPolicy: 'no-cache',
      variables(this: ExportPresetsView): AppAdminCustomerExportPresetsViewQueryVariables {
        return { id: this.customerId, skip: this.skip, take: this.take };
      },
    },
  },
  data() {
    return { customer: undefined };
  },
})
export default class ExportPresetsView extends Mixins(PaginationQueryStringMixin) {
  @StringProp(true)
  private readonly customerId!: string;

  private readonly customer?: AppAdminCustomerExportPresetsViewQuery['customer'];

  protected get count(): number {
    return this.customer?.first.exportPresets.count ?? 0;
  }
}
