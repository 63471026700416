import { NodeTreeChangesManagerEditTreeNodeInput } from '../../node-tree-changes-manager/model';
import { Directory } from './directory/model';
import { PropertyGroup } from './property-group/model';
import { Property, PropertySubdivision } from './property/model';

export const NODE_EDITOR_ADD_PROPERTY_SUBDIVISION = Symbol('NODE_EDITOR_PROPERTY_SUBDIVISION');
export const NODE_EDITOR_REMOVE_PROPERTY_SUBDIVISION = Symbol('NODE_EDITOR_REMOVE_PROPERTY_SUBDIVISION');
export const NODE_EDITOR_EDIT_NODE = Symbol('NODE_EDITOR_EDIT_NODE');

export type NodeEditorAddPropertySubdivision = (data: PropertySubdivision) => Promise<void>;
export type NodeEditorRemovePropertySubdivision = (id: string) => Promise<void>;
export type NodeEditorEditNode = (input: NodeTreeChangesManagerEditTreeNodeInput, contractId?: string) => Promise<void>;

export interface NodeEditorProvider {
  nodeEditorAddPropertySubdivision: NodeEditorAddPropertySubdivision;
  nodeEditorRemovePropertySubdivision: NodeEditorRemovePropertySubdivision;
  nodeEditorEditNode: NodeEditorEditNode;
}

export * from './directory/model';
export * from './property-group/model';
export * from './property/model';

export type NodeEditorNode = Directory | PropertyGroup | Property;
