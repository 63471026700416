var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-table',{attrs:{"columns":_vm.columns,"rows":_vm.flatCodes,"selected-rows":_vm.modelSelectedFlatCodes,"selectable":""},on:{"update:selectedRows":function($event){_vm.modelSelectedFlatCodes=$event},"update:selected-rows":function($event){_vm.modelSelectedFlatCodes=$event}},scopedSlots:_vm._u([{key:"object",fn:function(ref){
var value = ref.value;
return [(value)?_c('div',{staticClass:"text"},[_c('domain-ui-tree-node-path',{attrs:{"tree-nodes":value.path.items,"hide-secondary-tree-node":true},on:{"tree-node-click":function($event){return _vm.$router.push({ name: 'AppManager/TreeNode', params: { treeNodeId: $event.id } })}}})],1):_vm._e()]}},{key:"flat",fn:function(ref){
var value = ref.value;
return [(value)?_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.getFlatCode(value)))]):_vm._e()]}},{key:"users",fn:function(ref){
var value = ref.value;
return [(value.length)?_c('div',{staticClass:"status_labels"},[_vm._l((value),function(val){return [_c('ui-label',{staticClass:"user",attrs:{"red":false,"green":false,"orange":false}},[_vm._v(_vm._s(val.email))])]})],2):_c('div',{staticClass:"text"})]}},{key:"status",fn:function(ref){
var value = ref.value;
return [(value.length)?_c('div',{staticClass:"status_labels"},[_vm._l((value),function(val){return [_c('ui-label',{attrs:{"green":val.emailVerified,"orange":!val.emailVerified}},[_vm._v(_vm._s(val.emailVerified ? 'Verfiziert' : 'Nicht verifiziert'))])]})],2):_c('div',{staticClass:"ui-label"})]}},{key:"expirationDate",fn:function(ref){
var value = ref.value;
return [_c('ui-date',{class:{ expired: _vm.isExpired(value) },attrs:{"date":value,"format":"LLL"}})]}},{key:"accessDate",fn:function(ref){
var value = ref.value;
return [(value !== null)?_c('ui-date',{attrs:{"date":value,"format":"LLL"}}):_vm._e()]}},{key:"actions",fn:function(ref){
var row = ref.row;
return [_c('ui-flex',{attrs:{"nowrap":""}},[_c('ui-cell',{attrs:{"basis":0.5,"grow":0}},[(_vm.isExpired(row.expirationDate))?[_vm._v("Link abgelaufen")]:[_c('ui-link',{on:{"click":function($event){return _vm.copyToClipboard(row)}}},[_vm._v("Registrierungslink kopieren")])]],2),_c('ui-cell',{attrs:{"basis":0.5,"grow":0}},[_c('ui-link',{on:{"click":function($event){return _vm.goToFlatCode(row)}}},[_vm._v("Bearbeiten")])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }