























import { PaginationQueryStringMixin } from '@/features/core/components/mixins/pagination-query-string';
import plusIcon from '@/features/ui/assets/icons/plus.svg';
import { StringProp } from '@/util/prop-decorators';
import { Component, Mixins } from 'vue-property-decorator';
import {
  AppAdminCustomerContractsViewQuery,
  AppAdminCustomerContractsViewQueryVariables,
} from './__generated__/AppAdminCustomerContractsViewQuery';
import query from './view.gql';

@Component({
  apollo: {
    customer: {
      query,
      fetchPolicy: 'no-cache',
      variables(this: ContractsView): AppAdminCustomerContractsViewQueryVariables {
        return { id: this.customerId, skip: this.skip, take: this.take };
      },
    },
  },
  data() {
    return { customer: undefined };
  },
})
export default class ContractsView extends Mixins(PaginationQueryStringMixin) {
  @StringProp(true)
  private readonly customerId!: string;

  private readonly customer?: AppAdminCustomerContractsViewQuery['customer'];
  private readonly plusIcon = plusIcon;

  protected get count(): number {
    return this.customer?.first.contracts.count ?? 0;
  }
}
