














































import { Component, Vue, Watch } from 'vue-property-decorator';
import { FormData, ReportRow } from './model';
import { ORIGIN_ENDPOINT } from '@/env';
import { keycloak } from '@/keycloak';
import { Column } from '@/features/ui/table/model';
import Flex from '@/features/ui/layout/Flex.global.vue';
import Wizard from '@/features/ui/modal/Wizard.global.vue';

@Component
export default class PropertyImportModal extends Vue {
  private report: Array<ReportRow> = [];
  private errors: Array<string> = [];
  private inProgress = false;
  private startDate: Date | null = null;

  public $refs!: { wizard: Wizard; summary: Flex };

  private get reportEvents(): Array<ReportRow> {
    return this.report.filter((r) => r.Row !== 0);
  }

  private get reportMeta(): Array<ReportRow> {
    return this.report.filter((r) => r.Row === 0);
  }

  private readonly columns: Column[] = [
    { name: 'Row', label: 'Zeile' },
    { name: 'Messages', label: 'Ereignisse' },
    { name: 'Successful', label: 'Status' },
  ];

  private importProperties(data: FormData): Promise<void> {
    this.report = [];
    this.inProgress = true;
    this.startDate = new Date();

    return fetch(`${ORIGIN_ENDPOINT}/property-importer`, {
      method: 'POST',
      headers: { authorization: `Bearer ${keycloak?.token ?? ''}` },
      body: data.file,
    })
      .then((response) => {
        if (!response.body) {
          this.$refs.wizard.hide(); //hide this dialog
          this.errors.push('Bad request: No response body available.');
          return;
        }

        if (!response.ok) {
          this.$refs.wizard.hide(); //hide this dialog
          return response.json().then((error) => {
            this.errors.push(error.title);
          });
        }
        return this.processLines(response.body.getReader(), (line) => {
          const reportRow: ReportRow = JSON.parse(line);

          this.report.push(reportRow);
        });
      })
      .catch((e) => {
        this.$refs.wizard.hide(); //hide this dialog
        this.errors.push(`${e}`);
      });
  }

  //this is a helper function which will take a ready and call the callback for each read line
  private async processLines(
    reader: ReadableStreamDefaultReader<Uint8Array>,
    clbLine: (s: string) => void,
  ): Promise<void> {
    const utf8Decoder = new TextDecoder('utf-8');
    const re = /\n|\r|\r\n/gm;

    let readResult = await reader.read();
    let chunk = readResult.value ? utf8Decoder.decode(readResult.value) : '';

    let startIndex = 0;

    // eslint-disable-next-line no-constant-condition
    while (true) {
      const result = re.exec(chunk);

      //no newline is given
      if (!result) {
        //stream ends?
        if (readResult.done) {
          break;
        }

        const remainder = chunk.substr(startIndex);
        readResult = await reader.read();
        chunk = remainder + (readResult.value ? utf8Decoder.decode(readResult.value) : '');
        startIndex = re.lastIndex = 0;
        continue;
      }
      const line = chunk.substring(startIndex, result.index);
      clbLine(line);

      startIndex = re.lastIndex;
    }
    if (startIndex < chunk.length) {
      // last line didn't end in a newline char
      const line = chunk.substr(startIndex);
      clbLine(line);
    }
  }

  private isValid({ file }: FormData): boolean {
    return file !== null;
  }

  private downloadReportAsCsv(): void {
    if (!this.startDate) return;

    let content = 'data:text/csv;charset=utf-8,Zeile;Ereignisse;Status';
    const fileName =
      `property_import_` +
      `${this.startDate.getFullYear()}-` +
      `${('' + (this.startDate.getMonth() + 1)).padStart(2, '0')}-` +
      `${('' + this.startDate.getDate()).padStart(2, '0')}-` +
      `${('' + this.startDate.getHours()).padStart(2, '0')}` +
      `${('' + this.startDate.getMinutes()).padStart(2, '0')}` +
      `${('' + this.startDate.getSeconds()).padStart(2, '0')}.csv`;

    this.reportEvents.forEach((r) => {
      content +=
        '\n' +
        `${r.Row},` +
        `"${r.Messages.map((m) => m.Text.replace('"', '""')).join('\n')}",` +
        `${r.Successful ? 'Ja' : 'Nein'}`;
    });

    const link = document.createElement('a');
    link.setAttribute('href', encodeURI(content));
    link.setAttribute('download', fileName);
    link.click();
  }

  private onHide(): void {
    const importedSuccessfully = this.report.length > 0;

    //reset data after user close the wizard
    this.report = [];
    this.errors = [];
    this.inProgress = false;
    this.startDate = null;

    if (importedSuccessfully) {
      this.$emit('properties-imported', { report: this.report });
    }
  }

  @Watch('report')
  private onReportChange(): void {
    this.$nextTick(function () {
      this.$refs.summary.$el.scrollIntoView({ behavior: 'smooth' });
    });
  }
}
