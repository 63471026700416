


































import { ATTRIBUTE_CONFIGURATION_TYPE_META } from '@/features/domain-ui/attribute-configuration-type/constants';
import { Option } from '@/features/ui/inputs/model';
import { AddAttributeDefinitionTemplateInput, AttributeConfigurationType } from '@/types/iot-portal';
import { isString } from 'lodash';
import { Component, Vue } from 'vue-property-decorator';
import addAttributeDefinitionTemplateMutation from './add-attribute-definition-template.gql';
import {
  AppAdminAddAttributeDefinitionTemplateWizardControlMutation,
  AppAdminAddAttributeDefinitionTemplateWizardControlMutationVariables,
} from './__generated__/AppAdminAddAttributeDefinitionTemplateWizardControlMutation';

type AttributeDefinitionTemplate =
  AppAdminAddAttributeDefinitionTemplateWizardControlMutation['addAttributeDefinitionTemplate']['attributeDefinitionTemplate'];

@Component({
  data() {
    return {
      emptyArray: [],
    };
  },
})
export default class AddAttributeDefinitionTemplateWizardControl extends Vue {
  private get attributeTypeOptions(): Option<AttributeConfigurationType>[] {
    return Object.values(ATTRIBUTE_CONFIGURATION_TYPE_META).sort((a, b) => a.label.localeCompare(b.label));
  }

  private async addAttributeDefinitionTemplate(
    input: AddAttributeDefinitionTemplateInput,
  ): Promise<AttributeDefinitionTemplate> {
    const { data } = await this.$apollo.mutate<
      AppAdminAddAttributeDefinitionTemplateWizardControlMutation,
      AppAdminAddAttributeDefinitionTemplateWizardControlMutationVariables
    >({
      mutation: addAttributeDefinitionTemplateMutation,
      variables: {
        input: {
          ...input,
          treeNodeTypes: [],
        },
      },
    });

    if (!data) {
      throw new Error('Die Attributvorlage konnte nicht angelegt werden!');
    }

    return data.addAttributeDefinitionTemplate.attributeDefinitionTemplate;
  }

  private isNonEmptyString(value: unknown): boolean {
    return isString(value) && value.length > 0;
  }

  private isValidData(data: Partial<AddAttributeDefinitionTemplateInput>): boolean {
    return this.isNonEmptyString(data.name) && this.isNonEmptyString(data.type);
  }

  private async onSubmitted(template: AttributeDefinitionTemplate, hide: () => Promise<void>): Promise<void> {
    await hide();

    this.$emit('attribute-definition-template-added', template);
  }
}
