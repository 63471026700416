























































































































































import DeviceRoleMapMixin from '@/features/core/components/mixins/device-role-map';
import { Action, RootAction } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';
import {
  AlertRuleStrategyType,
  ALERT_RULE_STRATEGY_TYPE_META,
} from '@/features/domain-ui/alert-rule-strategy-type/constants';
import { Option } from '@/features/ui/inputs/model';
import {
  ConfigureLatestMetricsAlertRuleStrategyInput,
  EditAlertRuleInput,
  UnconfigureAlertRuleStrategyInput,
} from '@/types/iot-portal';
import { isDef } from '@/util/lang';
import { omitDeepBy } from '@/util/omit-deep-by';
import { StringProp } from '@/util/prop-decorators';
import { ApolloQueryResult } from 'apollo-client';
import { DocumentNode } from 'graphql';
import { Component, Mixins } from 'vue-property-decorator';
import configureLatestMetricsAlertRuleStrategyMutation from './configure-latest-metrics-alert-rule-strategy.gql';
import editAlertRuleMutation from './edit-alert-rule.gql';
import unconfigureAlertRuleStrategyMutation from './unconfigure-alert-rule-strategy.gql';
import query from './view.gql';
import {
  AppAdminAlertRuleViewConfigureLatestMetricsAlertRuleStrategyMutation,
  AppAdminAlertRuleViewConfigureLatestMetricsAlertRuleStrategyMutationVariables,
} from './__generated__/AppAdminAlertRuleViewConfigureLatestMetricsAlertRuleStrategyMutation';
import {
  AppAdminAlertRuleViewEditAlertRuleMutation,
  AppAdminAlertRuleViewEditAlertRuleMutationVariables,
} from './__generated__/AppAdminAlertRuleViewEditAlertRuleMutation';
import {
  AppAdminAlertRuleViewQuery,
  AppAdminAlertRuleViewQueryVariables,
} from './__generated__/AppAdminAlertRuleViewQuery';
import {
  AppAdminAlertRuleViewUnconfigureAlertRuleStrategyMutation,
  AppAdminAlertRuleViewUnconfigureAlertRuleStrategyMutationVariables,
} from './__generated__/AppAdminAlertRuleViewUnconfigureAlertRuleStrategyMutation';

const QUERY_MAP: Partial<Record<AlertRuleStrategyType, DocumentNode>> = {
  LatestMetricsAlertRuleStrategy: configureLatestMetricsAlertRuleStrategyMutation,
  UnknownAlertRuleStrategy: unconfigureAlertRuleStrategyMutation,
};

type ConfigureAlertRuleStrategyInput = ConfigureLatestMetricsAlertRuleStrategyInput | UnconfigureAlertRuleStrategyInput;
type ConfigureAlertRuleStrategyMutation =
  | AppAdminAlertRuleViewConfigureLatestMetricsAlertRuleStrategyMutation
  | AppAdminAlertRuleViewUnconfigureAlertRuleStrategyMutation;
type ConfigureAlertRuleStrategyMutationVariables =
  | AppAdminAlertRuleViewConfigureLatestMetricsAlertRuleStrategyMutationVariables
  | AppAdminAlertRuleViewUnconfigureAlertRuleStrategyMutationVariables;

enum Release {
  BLOCKED = 'BLOCKED',
  ALL = 'ALL',
  CUSTOMERS = 'CUSTOMERS',
}

interface FormData {
  editAlertRuleInput: EditAlertRuleInput;
  configureAlertRuleStrategyInput: ConfigureAlertRuleStrategyInput;
  release: Release;
  strategyType: AlertRuleStrategyType;
}

@Component({
  apollo: {
    view: {
      query,
      fetchPolicy: 'network-only',
      variables(this: AlertRuleView): AppAdminAlertRuleViewQueryVariables {
        return { id: this.alertRuleId };
      },
      manual: true,
      result(this: AlertRuleView, { data }: ApolloQueryResult<AppAdminAlertRuleViewQuery>): void {
        this.alertRules = data.alertRules;
        this.customers = data.customers;
      },
    },
  },
  data() {
    return { alertRules: undefined, customers: undefined, emptyArray: [] };
  },
})
export default class AlertRuleView extends Mixins(DeviceRoleMapMixin) {
  @StringProp(true)
  private readonly alertRuleId!: string;

  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  private alertRules?: AppAdminAlertRuleViewQuery['alertRules'];
  private customers?: AppAdminAlertRuleViewQuery['customers'];
  private readonly emptyArray!: never[];

  private get initialData(): {
    rule?: AppAdminAlertRuleViewQuery['alertRules']['first'];
    release: Release;
  } {
    return {
      rule: this.alertRules?.first,
      release:
        this.alertRules?.first.selectable === false
          ? Release.BLOCKED
          : this.alertRules?.first.allowedCustomers === null
          ? Release.ALL
          : Release.CUSTOMERS,
    };
  }

  private get releaseOptions(): Option<Release>[] {
    return [
      { value: Release.BLOCKED, label: 'Gesperrt' },
      { value: Release.ALL, label: 'Für alle Kunden freigegeben' },
      { value: Release.CUSTOMERS, label: 'Nur für ausgewählte Kunden freigegeben' },
    ];
  }

  private get roleOptions(): Option[] {
    return Object.values(this.deviceRoleMap)
      .map(({ name, label }) => ({ value: name, label }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  private get strategyOptions(): Option[] {
    return Object.values(ALERT_RULE_STRATEGY_TYPE_META)
      .filter(isDef)
      .map(({ value, label, optionLabel }) => ({ value, label: optionLabel ?? label }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  private async save({
    editAlertRuleInput,
    configureAlertRuleStrategyInput,
    release,
    strategyType,
  }: FormData): Promise<void> {
    await this.editAlertRule(editAlertRuleInput, release);
    await this.configureAlertRuleStrategy(strategyType, configureAlertRuleStrategyInput);

    this.ADD_TOAST_MESSAGES({
      messages: [{ text: 'Regelvorlage gespeichert!', class: 'success' }],
    });
  }

  private async editAlertRule({ allowedCustomerIds, ...input }: EditAlertRuleInput, release: Release): Promise<void> {
    const { data } = await this.$apollo.mutate<
      AppAdminAlertRuleViewEditAlertRuleMutation,
      AppAdminAlertRuleViewEditAlertRuleMutationVariables
    >({
      mutation: editAlertRuleMutation,
      variables: {
        input: {
          ...input,
          id: this.alertRuleId,
          selectable: release !== Release.BLOCKED,
          allowedCustomerIds: release === Release.CUSTOMERS ? allowedCustomerIds : null,
        },
      },
    });

    if (!data) {
      throw new Error('Alarmregelvorlage konnte nicht gespeichert werden.');
    }
  }

  private async configureAlertRuleStrategy(
    strategyType: AlertRuleStrategyType,
    input: ConfigureAlertRuleStrategyInput,
  ): Promise<void> {
    const mutation = QUERY_MAP[strategyType];
    if (mutation === undefined) {
      throw new Error(`No mutation found to configure alert rule strategy "${strategyType}"`);
    }

    const { data } = await this.$apollo.mutate<
      ConfigureAlertRuleStrategyMutation,
      ConfigureAlertRuleStrategyMutationVariables
    >({
      mutation,
      variables: { input: { ...input, id: this.alertRuleId } },
    });

    if (!data) {
      throw new Error('Alarmregelvorlage konnte nicht konfiguriert werden.');
    }
  }

  private jsonStringify(value: unknown): string {
    return JSON.stringify(
      omitDeepBy(value, (value, key) => key === '__typename'),
      undefined,
      2,
    );
  }

  private jsonParse(value: string): unknown {
    try {
      return JSON.parse(value);
    } catch {
      return null;
    }
  }

  private pluckIds<T>(objects: { id: T }[] | null): T[] | null {
    return objects?.map(({ id }) => id) ?? null;
  }
}
