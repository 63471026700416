

































import { FunctionProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import { AddFloorAction } from './model';

@Component
export default class AddPropertyFloorModal extends Vue {
  @FunctionProp(() => Promise.resolve())
  private readonly action!: AddFloorAction;

  private async onSubmitted(hide: () => Promise<void>): Promise<void> {
    await hide();
  }

  private isLevelValid(level: string): boolean {
    return Number.isInteger(Number(level));
  }
}
