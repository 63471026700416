import Vue from 'vue';
import {
  NodeTreeChangesManagerAddTreeNodeInput,
  NodeTreeChangesManagerAddTreeNodeInputWithChildren,
  NodeTreeChangesManagerChange,
  NodeTreeChangesManagerChangeAddNode,
  NodeTreeChangesManagerChangeAddNodeWithChildren,
  NodeTreeChangesManagerChangeWithChildren,
  NodeTreeChangesManagerEditTreeNodeInput,
} from './model';

function unflattenAddTreeNodeChanges(
  ids: string[],
  changes: Map<string, NodeTreeChangesManagerAddTreeNodeInputWithChildren>,
): void {
  for (const id of ids) {
    const change = changes.get(id);
    if (change === undefined || change.children !== undefined) {
      continue;
    }
    const childChanges: NodeTreeChangesManagerAddTreeNodeInput[] = Array.from(changes.values()).filter(
      (childChange) => childChange.parentId === change.temporaryId,
    );
    change.children = childChanges;
    unflattenAddTreeNodeChanges(
      change.children.map(({ temporaryId }) => temporaryId),
      changes,
    );
    for (const change of childChanges) {
      changes.delete(change.temporaryId);
    }
  }
}

export default class NodeTreeChangesManager {
  public changesMap: { [key: string]: NodeTreeChangesManagerChange };

  public constructor() {
    this.changesMap = {};
  }

  public hasChanges(): boolean {
    return Object.keys(this.changesMap).length > 0;
  }

  public getChanges(): NodeTreeChangesManagerChange[] {
    return Object.values(this.changesMap);
  }

  public getChangesWithChildren(): NodeTreeChangesManagerChangeWithChildren[] {
    const addChanges = Object.values(this.changesMap).filter((change) => change.type === 'Add');
    const addChangesInputs = addChanges.map((change) => change.input) as NodeTreeChangesManagerAddTreeNodeInput[];
    const flatInputsMap = new Map(addChangesInputs.map((input) => [input.temporaryId, { ...input }]));
    unflattenAddTreeNodeChanges(
      addChangesInputs.map(({ temporaryId }) => temporaryId),
      flatInputsMap,
    );

    const unflattenedAddChanges: NodeTreeChangesManagerChangeAddNodeWithChildren[] = Array.from(
      flatInputsMap.values(),
    ).map((addInput) => ({
      type: 'Add',
      input: addInput,
    }));

    return [
      ...unflattenedAddChanges,
      ...Object.values(this.changesMap).filter((change) => change.type === 'Edit' || change.type === 'Remove'),
    ];
  }

  public reset(): void {
    this.changesMap = {};
  }

  public addNode(input: NodeTreeChangesManagerAddTreeNodeInput): void {
    Vue.set(this.changesMap, input.temporaryId, {
      type: 'Add',
      input,
    });
  }

  public removeNode(nodeId: string): void {
    if (this.changesMap[nodeId] && this.changesMap[nodeId]?.type === 'Add') {
      Vue.delete(this.changesMap, nodeId);
      return;
    }
    if (this.changesMap[nodeId] && this.changesMap[nodeId]?.type === 'Edit') {
      Vue.delete(this.changesMap, nodeId);
    }

    Vue.set(this.changesMap, nodeId, {
      type: 'Remove',
      input: {
        ids: [nodeId],
      },
    });
  }

  public editNode(input: NodeTreeChangesManagerEditTreeNodeInput): void {
    const existingChange = this.get(input.input.id);

    Vue.set(this.changesMap, input.input.id, {
      type: 'Edit',
      input:
        existingChange && existingChange.type === 'Edit' && input.type === 'TreeNode'
          ? { ...existingChange.input, input: { ...existingChange.input.input, ...input.input } }
          : input,
    });
  }

  public get(id: string): NodeTreeChangesManagerChange | undefined {
    return this.changesMap[id];
  }

  public getAddChangesByParentId(parentId: string): NodeTreeChangesManagerChangeAddNode[] {
    const addChanges = Array.from(Object.values(this.changesMap)).filter(
      (change) => change.type === 'Add',
    ) as NodeTreeChangesManagerChangeAddNode[];

    return addChanges.filter((change) => change.input.parentId === parentId);
  }

  public unset(id: string): void {
    delete this.changesMap[id];
  }
}
