























import { StringProp, RequiredProp } from '@/util/prop-decorators';
import { Component, Inject } from 'vue-property-decorator';
import Vue from 'vue';
import EditableValue from '@/components/editable-value/EditableValue.global.vue';
import close from '@/assets/images/close.svg';
import roomIcon from '@/features/app-admin/assets/room.svg';
import apartmentIcon from '@/features/app-admin/assets/apartment.svg';
import stairsIcon from '@/features/app-admin/assets/stairs.svg';
import { PropertySubdivisionVisualizationHint } from '@/types/iot-portal';
import { NODE_EDITOR_EDIT_NODE, NodeEditorEditNode } from '../model';
import { AppAdminCustomerManagePropertiesNodeEditorPropertySubdivisionFragment } from './__generated__/AppAdminCustomerManagePropertiesNodeEditorPropertySubdivisionFragment';
import PropertySubdivisionRoomsModal from './PropertySubdivisionRoomsModal.vue';
import { omit } from 'lodash';

type PropertySubdivision = AppAdminCustomerManagePropertiesNodeEditorPropertySubdivisionFragment;

@Component({
  components: { EditableValue, PropertySubdivisionRoomsModal },
})
export default class PropertyEditorPropertySubdivision extends Vue {
  @RequiredProp()
  private propertySubdivision!: PropertySubdivision;

  @StringProp()
  private color?: string;

  @Inject(NODE_EDITOR_EDIT_NODE)
  protected editNode!: NodeEditorEditNode;

  private close = close;

  private roomIcon = roomIcon;
  private apartmentIcon = apartmentIcon;
  private stairsIcon = stairsIcon;

  private get style(): unknown {
    return {
      backgroundColor: this.color || '#f7f7f7',
    };
  }

  private get icon(): unknown {
    return this.propertySubdivision.visualizationHint === PropertySubdivisionVisualizationHint.APARTMENT
      ? this.apartmentIcon
      : this.propertySubdivision.visualizationHint === PropertySubdivisionVisualizationHint.STAIRWELL
      ? this.stairsIcon
      : this.roomIcon;
  }

  private editPropertySubdivision(): void {
    this.editNode({
      type: 'PropertySubdivision',
      input: {
        id: this.propertySubdivision.id,
        name: this.propertySubdivision.name,
        order: Number(this.propertySubdivision.order),
        rooms: this.propertySubdivision.rooms.map((room) => omit(room, '__typename')),
        visualizationHint: this.propertySubdivision.visualizationHint,
        floorLevel: this.propertySubdivision.floorLevel,
        position: this.propertySubdivision.position,
        size: this.propertySubdivision.size,
        externalType: this.propertySubdivision.externalType,
        externalId: this.propertySubdivision.externalId,
      },
    });
  }

  private isApartment(node: PropertySubdivision): boolean {
    return node.visualizationHint === PropertySubdivisionVisualizationHint.APARTMENT;
  }
}
