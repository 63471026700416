import { render, staticRenderFns } from "./DirectoryEditor.vue?vue&type=template&id=38c4fae2&scoped=true&lang=pug&"
import script from "./DirectoryEditor.vue?vue&type=script&lang=ts&"
export * from "./DirectoryEditor.vue?vue&type=script&lang=ts&"
import style0 from "./DirectoryEditor.vue?vue&type=style&index=0&id=38c4fae2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38c4fae2",
  null
  
)

export default component.exports