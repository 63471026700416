
import { Component, Vue, Inject } from 'vue-property-decorator';
import UiClickable from '@/components/clickables/Clickable.global.vue';
import { RequiredProp, NumberProp } from '@/util/prop-decorators';
import {
  NodeListNode,
  NODE_LIST_ON_NODE_CLICK,
  NODE_LIST_REMOVE_NODE,
  NODE_LIST_ADD_NODE,
  NODE_LIST_EDIT_NODE,
  NodeListOnNodeClick,
  NodeListRemoveNode,
  NodeListAddNode,
  NodeListEditNode,
} from './model';
import NodeList from './NodeList.vue';

@Component({ components: { UiClickable }, name: 'node' })
export default class Node extends Vue {
  @RequiredProp()
  protected node!: NodeListNode;

  @RequiredProp()
  protected parent!: NodeListNode | undefined;

  @NumberProp()
  private level!: number;

  private nodeList = NodeList;

  @Inject(NODE_LIST_ON_NODE_CLICK)
  protected onClick!: NodeListOnNodeClick;

  @Inject(NODE_LIST_REMOVE_NODE)
  protected removeNode!: NodeListRemoveNode;

  @Inject(NODE_LIST_ADD_NODE)
  protected addNode!: NodeListAddNode;

  @Inject(NODE_LIST_EDIT_NODE)
  protected editNode!: NodeListEditNode;

  get children(): NodeListNode[] | undefined {
    return this.node.children;
  }

  protected get style(): string {
    return `padding-left: ${this.level * 8}px`;
  }
}
