





















import { ObjectProp } from '@/util/prop-decorators';
import { AddExportPresetInput } from '@/types/iot-portal';
import { Component, Vue } from 'vue-property-decorator';
import {
  AppAdminCustomerAddExportPresetWizardControlMutation,
  AppAdminCustomerAddExportPresetWizardControlMutationVariables,
} from './__generated__/AppAdminCustomerAddExportPresetWizardControlMutation';
import addExportPresetMutation from './add-export-preset.gql';

type ExportPreset = AppAdminCustomerAddExportPresetWizardControlMutation['addExportPreset']['exportPreset'];

@Component
export default class AddExportPresetWizardControl extends Vue {
  @ObjectProp()
  private readonly initialData!: Pick<AddExportPresetInput, 'customerId'>;

  public async addExportPreset(input: Pick<AddExportPresetInput, 'name'>): Promise<ExportPreset> {
    const { data } = await this.$apollo.mutate<
      AppAdminCustomerAddExportPresetWizardControlMutation,
      AppAdminCustomerAddExportPresetWizardControlMutationVariables
    >({
      mutation: addExportPresetMutation,
      variables: { input: { ...this.initialData, ...input } },
    });

    if (!data) {
      throw new Error('Die Exportvorlage konnte nicht angelegt werden!');
    }

    return data.addExportPreset.exportPreset;
  }

  private async onSubmitted(exportPreset: ExportPreset, hide: () => Promise<void>): Promise<void> {
    await hide();

    this.$emit('export-preset-added', exportPreset);
  }
}
