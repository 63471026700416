










import { Column } from '@/features/ui/table/model';
import { TimeframeType } from '@/types/iot-portal';
import { ArrayProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import { ExportPresetRow } from './model';

function timeframeComparator(a: TimeframeType, b: TimeframeType): number {
  return a.localeCompare(b);
}

@Component({
  data() {
    return {
      timeframeComparator,
    };
  },
})
export default class ExportPresetTable extends Vue {
  @ArrayProp(() => [])
  private readonly exportPresets!: ExportPresetRow[];

  private readonly columns: Column[] = [
    { name: 'name', label: 'Name' },
    { name: 'mimeType', label: 'MIME Type' },
    { name: 'timeframes', label: 'Time Frames' },
    { name: 'actions', label: '' },
  ];
}
