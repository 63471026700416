






















































import { Component, Inject } from 'vue-property-decorator';
import TextInput from '@/components/form/TextInput.vue';
import { AddUserInput, EditPropertyGroupInput } from '@/types/iot-portal';
import NodeEditor from '../NodeEditor.vue';
import { omit } from 'lodash';
import PropertyBuildingEditor from '../property/PropertyBuildingEditor.vue';
import { NodeEditorEditNode, NODE_EDITOR_EDIT_NODE } from '../model';

import { PropertySubdivision } from '../property/model';
import { PropertyGroup } from './model';
import { BooleanProp, StringProp } from '@/util/prop-decorators';

@Component({
  components: {
    TextInput,
    PropertyBuildingEditor,
  },
})
export default class PropertyGroupEditor extends NodeEditor {
  @StringProp(true)
  private readonly customerId!: string;

  @BooleanProp(true)
  private readonly canEditContract!: boolean;

  private get propertyGroup(): PropertyGroup {
    return this.node as PropertyGroup;
  }

  private get propertySubdivisions(): PropertySubdivision[] {
    return this.propertyGroup.children.items ?? [];
  }

  private async onEdit(): Promise<void> {
    const input: EditPropertyGroupInput = {
      ...omit(this.propertyGroup, ['__typename', 'technicalContact', 'children', 'contract']),
      floors: this.propertyGroup.floors.map((floor) => omit(floor, '__typename')),
      technicalContactId: this.propertyGroup.technicalContact?.id ?? null,
    };
    await this.nodeEditorEditNode(
      {
        type: 'PropertyGroup',
        input,
        technicalContactData: (this.propertyGroup.technicalContact as AddUserInput) ?? undefined,
      },
      this.propertyGroup.contract.id,
    );
  }

  @Inject(NODE_EDITOR_EDIT_NODE)
  private nodeEditorEditNode!: NodeEditorEditNode;
}
