






























import { Component, Provide } from 'vue-property-decorator';
import customerNodeTreeQuery from './customer-node-tree-query.gql';
import { ApolloQueryResult } from 'apollo-client';
import {
  AppAdminCustomerManagePropertiesCustomerNodeTreeQuery,
  AppAdminCustomerManagePropertiesCustomerNodeTreeQueryVariables,
} from './__generated__/AppAdminCustomerManagePropertiesCustomerNodeTreeQuery';
import { StringProp } from '@/util/prop-decorators';
import {
  NODE_LIST_ADD_NODE,
  NODE_LIST_EDIT_NODE,
  NodeListNode,
  NODE_LIST_ON_NODE_CLICK,
  NODE_LIST_ON_NODE_TOGGLE,
  NODE_LIST_REMOVE_NODE,
  NodeListProvider,
  NodeListNodeType,
  NODE_LIST_EDIT_NODE_ORDER,
} from './components/node-list/model';
import { AddTreeNodeInput, AddUserInput, TreeNodeType } from '@/types/iot-portal';
import { FetchResult } from 'apollo-link';
import {
  AppAdminCustomerManagePropertiesAddTreeNodeMutation,
  AppAdminCustomerManagePropertiesAddTreeNodeMutationVariables,
} from './__generated__/AppAdminCustomerManagePropertiesAddTreeNodeMutation';
import addTreeNodeMutation from './add-tree-node.gql';
import removeNodeMutation from './remove-tree-node.gql';
import {
  AppAdminCustomerManagePropertiesRemoveTreeNodeMutation,
  AppAdminCustomerManagePropertiesRemoveTreeNodeMutationVariables,
} from './__generated__/AppAdminCustomerManagePropertiesRemoveTreeNodeMutation';
import NodeList from './components/node-list/NodeList.vue';
import Vue from 'vue';
import CsvSelectorModal from '../../../components/csv-selector-modal/CsvSelectorModal.global.vue';
import NodeEditorContainer from './components/node-editor/NodeEditorContainer.vue';
import editTreeNodeMutation from './edit-tree-node.gql';
import editDirectoryMutation from './edit-directory.gql';
import editPropertyMutation from './edit-property.gql';
import editPropertyGroupMutation from './edit-property-group.gql';
import editPropertySubdivisionMutation from './edit-property-subdivision.gql';
import { cloneDeep, omit, uniqBy } from 'lodash';
import {
  AppAdminCustomerManagePropertiesNodeListNodeToggleQuery,
  AppAdminCustomerManagePropertiesNodeListNodeToggleQueryVariables,
} from './__generated__/AppAdminCustomerManagePropertiesNodeListNodeToggleQuery';
import nodeListNodeToggleQuery from './node-list-node-toggle.gql';
import { AppAdminCustomerManagePropertiesNodeListNodeFragment } from './__generated__/AppAdminCustomerManagePropertiesNodeListNodeFragment';
import NodeTreeChangesManager from './node-tree-changes-manager/NodeTreeChangesManager';
import { v4 as uuidv4 } from 'uuid';
import {
  NodeTreeChangesManagerAddTreeNodeInput,
  NodeTreeChangesManagerAddTreeNodeInputWithChildren,
  NodeTreeChangesManagerChangeWithChildren,
  NodeTreeChangesManagerEditAfterAddPropertyInput,
  NodeTreeChangesManagerEditPropertyInput,
  NodeTreeChangesManagerEditTreeNodeInput,
  NodeTreeChangesManagerRemoveTreeNodeInput,
} from './node-tree-changes-manager/model';
import {
  NODE_EDITOR_ADD_PROPERTY_SUBDIVISION,
  NODE_EDITOR_REMOVE_PROPERTY_SUBDIVISION,
  NODE_EDITOR_EDIT_NODE,
  NodeEditorProvider,
  PropertySubdivision,
  Directory,
  Property,
  NodeEditorNode,
  PropertyGroup,
} from './components/node-editor/model';

import directoryEditContentQuery from './directory-edit-content.gql';
import propertyGroupEditContentQuery from './property-group-edit-content.gql';
import propertyEditContentQuery from './property-edit-content.gql';

import { AppAdminCustomerManagePropertiesNodeEditorDirectoryEditContentQuery } from './__generated__/AppAdminCustomerManagePropertiesNodeEditorDirectoryEditContentQuery';
import { AppAdminCustomerManagePropertiesNodeEditorDirectoryEditContentQueryVariables } from './__generated__/AppAdminCustomerManagePropertiesNodeEditorDirectoryEditContentQuery';
import { AppAdminCustomerManagePropertiesNodeEditorPropertyGroupEditContentQuery } from './__generated__/AppAdminCustomerManagePropertiesNodeEditorPropertyGroupEditContentQuery';
import { AppAdminCustomerManagePropertiesNodeEditorPropertyGroupEditContentQueryVariables } from './__generated__/AppAdminCustomerManagePropertiesNodeEditorPropertyGroupEditContentQuery';
import { AppAdminCustomerManagePropertiesNodeEditorPropertyEditContentQuery } from './__generated__/AppAdminCustomerManagePropertiesNodeEditorPropertyEditContentQuery';
import { AppAdminCustomerManagePropertiesNodeEditorPropertyEditContentQueryVariables } from './__generated__/AppAdminCustomerManagePropertiesNodeEditorPropertyEditContentQuery';

import { getNodeListWithChanges } from './util/changes-to-node-list';
import { getNodeEditorNodeWithChanges } from './util/changes-to-node-editor';
import {
  AppAdminCustomerManagePropertiesGetUserByEmailQuery,
  AppAdminCustomerManagePropertiesGetUserByEmailQueryVariables,
} from './__generated__/AppAdminCustomerManagePropertiesGetUserByEmailQuery';
import getUserByEmailQuery from './get-user-by-email.gql';
import addUserMutation from '@/features/app-admin/components/add-user-wizard-control/add-user.gql';
import {
  AppAdminAddUserMutation,
  AppAdminAddUserMutationVariables,
} from '@/features/app-admin/components/add-user-wizard-control/__generated__/AppAdminAddUserMutation';
import { AppAdminInputSelectContractControlContractFragment } from '@/features/app-admin/components/input-select-contract-control/__generated__/AppAdminInputSelectContractControlContractFragment';
import { Action, RootAction } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';
import eventBus from '@/util/event-bus';
import PropertyImportModal from './components/property-import/PropertyImportModal.vue';

type Node = AppAdminCustomerManagePropertiesNodeListNodeFragment & { children?: Node[] };
type Contract = AppAdminInputSelectContractControlContractFragment;

function searchNodeInNodeList(nodeList: NodeListNode[], id: string): NodeListNode | undefined {
  let node = nodeList.find((node) => node.id === id);
  if (node) {
    return node;
  }
  for (node of nodeList) {
    if (!node.children) {
      continue;
    }
    const childNode = searchNodeInNodeList(node.children, id);
    if (childNode) {
      return childNode;
    }
  }

  return undefined;
}

@Component({
  beforeRouteLeave(to, from, next) {
    if (!(this as ManagePropertiesView).dirty) {
      next();
      return;
    }
    const answer = window.confirm('You have unsaved changes which will be lost. Are you sure you want to leave?');
    if (answer) {
      next();
    } else {
      next(false);
    }
  },
  apollo: {
    nodeTree: {
      query: customerNodeTreeQuery,
      manual: true,
      variables(): AppAdminCustomerManagePropertiesCustomerNodeTreeQueryVariables {
        return {
          id: this.customerId,
        };
      },
      fetchPolicy: 'no-cache',
      skip(): boolean {
        return !this.customerId;
      },
      result(
        this: ManagePropertiesView,
        { data }: ApolloQueryResult<AppAdminCustomerManagePropertiesCustomerNodeTreeQuery>,
      ): void {
        this.originalNodeList = [];
        for (const node of data.customers.first.rootDirectory.children.items) {
          this.originalNodeList.push(this.createNodeListNode({ ...node, children: node.children.items }, false));
        }
        this.nodeList = this.originalNodeList;

        this.nodeToEdit = undefined;
        this.originalNodeToEdit = undefined;

        this.changesManager.reset();

        this.contracts = data.customers.first.contracts.items;
      },
    },
  },
  components: { NodeList, CsvSelectorModal, NodeEditorContainer, PropertyImportModal },
  data() {
    return {
      nodeToEdit: undefined,
      nodeList: [],
      changesManager: new NodeTreeChangesManager(),
      contracts: [],
    };
  },
})
export default class ManagePropertiesView extends Vue implements NodeListProvider, NodeEditorProvider {
  @StringProp()
  private readonly customerId!: string;

  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  private originalNodeToEdit?: NodeEditorNode;

  private nodeToEdit?: NodeEditorNode;

  private originalNodeList!: NodeListNode[];

  private nodeList!: NodeListNode[];

  private changesManager!: NodeTreeChangesManager;

  private contracts!: Contract[];

  private changeMutationErrorResults: PromiseRejectedResult[] = [];

  mounted(): void {
    eventBus.$on('nodeList:import', () => {
      eventBus.$emit('uiWizard:show', 'csv-import');
    });
  }

  private async toggleNodeListNode(node: NodeListNode): Promise<void> {
    node.open = !node.open;

    const originalNode = searchNodeInNodeList(this.originalNodeList, node.id);
    if (!originalNode) {
      return;
    }

    const { children = [] } = originalNode;

    // do not request data if it already exists
    const childNodeWithUnloadedChildren = children.find((child) => child.children === undefined);

    if (childNodeWithUnloadedChildren) {
      await this.extendNode(originalNode);
    }
  }

  private createNodeListNode(node: Node, open: boolean): NodeListNode {
    return {
      id: node.id,
      name: node.name,
      order: node.order,
      type: node.__typename as NodeListNodeType,
      roomsAndApartmentsCount: node.__typename === 'Property' ? node.propertySubdivisions.count : undefined,
      propertiesCount:
        node.__typename === 'Directory' || node.__typename === 'PropertyGroup' ? node.properties.count : undefined,
      floorsCount: node.__typename === 'Property' ? node.floors.length : undefined,
      open,
      children: node.children ? node.children.map((child) => this.createNodeListNode(child, false)) : undefined,
    };
  }

  private async extendNode(parentNode: NodeListNode): Promise<void> {
    const variables: AppAdminCustomerManagePropertiesNodeListNodeToggleQueryVariables = { id: parentNode.id };

    const { data } = await this.$apollo.query<AppAdminCustomerManagePropertiesNodeListNodeToggleQuery>({
      query: nodeListNodeToggleQuery,
      variables,
      fetchPolicy: 'no-cache',
    });

    const node = data.treeNodes.first;

    const extendedNode = this.createNodeListNode(
      { ...node, children: node.children.items.map((child) => ({ ...child, children: child.children.items })) },
      true,
    );
    Object.assign(parentNode, extendedNode);
  }

  private addRootDirectory(): void {
    this.changesManager.addNode({
      temporaryId: `temp_${uuidv4()}`,
      parentId: this.customerId,
      treeNode: {
        type: TreeNodeType.Directory,
        name: 'Neues Verzeichnis',
      },
    });
    this.nodeList = getNodeListWithChanges(this.nodeList, this.originalNodeList, this.customerId, this.changesManager);
  }

  private addRootProperty(): void {
    this.changesManager.addNode({
      temporaryId: `temp_${uuidv4()}`,
      parentId: this.customerId,
      treeNode: {
        type: TreeNodeType.Property,
        name: 'Neues Gebäude',
        contractId: this.contracts[0].id,
      },
    });
    this.nodeList = getNodeListWithChanges(this.nodeList, this.originalNodeList, this.customerId, this.changesManager);
  }

  private addRootPropertyGroup(): void {
    this.changesManager.addNode({
      temporaryId: `temp_${uuidv4()}`,
      parentId: this.customerId,
      treeNode: {
        type: TreeNodeType.PropertyGroup,
        name: 'Neues Gebäudegruppe',
        contractId: this.contracts[0].id,
      },
    });
    this.nodeList = getNodeListWithChanges(this.nodeList, this.originalNodeList, this.customerId, this.changesManager);
  }

  private get dirty(): boolean {
    return this.changesManager.hasChanges();
  }

  private async onChangeMutationSuccess(
    change: NodeTreeChangesManagerChangeWithChildren,
    result: FetchResult<unknown>,
  ): Promise<void> {
    if (change.type !== 'Add') {
      return;
    }
    const { data } = result as FetchResult<AppAdminCustomerManagePropertiesAddTreeNodeMutation>;
    if (!data) {
      return;
    }
    const addedNodesIds = data.addTreeNode.treeNodes.map(({ id }) => id);

    const flatAddTreeNodeInputs = [...this.generateFlatAddTreeNodeInputs(change.input)];

    const editInputs: (NodeTreeChangesManagerEditTreeNodeInput | undefined)[] = flatAddTreeNodeInputs.map(
      (addTreeNodeInput, index) => {
        if (!addTreeNodeInput.treeNode.editAfterAdd) {
          return undefined;
        }
        return {
          type: addTreeNodeInput.treeNode.type as NodeTreeChangesManagerEditTreeNodeInput['type'],
          input: {
            ...addTreeNodeInput.treeNode.editAfterAdd?.input,
            id: addedNodesIds[index],
          } as NodeTreeChangesManagerEditTreeNodeInput['input'],
        };
      },
    );

    await this.executeChangeMutationsInChunks(
      editInputs
        .filter((editInput) => editInput !== undefined)
        .map((input) => ({ type: 'Edit', input: input as NodeTreeChangesManagerEditTreeNodeInput })),
    );

    const newIdsMap: Record<string, string> = {};

    for (const [index, input] of flatAddTreeNodeInputs.entries()) {
      newIdsMap[input.temporaryId] = addedNodesIds[index];
    }

    flatAddTreeNodeInputs.forEach((addTreeNodeInput) => {
      this.updateNodeListIds(this.nodeList, newIdsMap);
      if (this.originalNodeToEdit && this.nodeToEdit && this.originalNodeToEdit.id === addTreeNodeInput.temporaryId) {
        this.originalNodeToEdit.id = newIdsMap[addTreeNodeInput.temporaryId];
        this.nodeToEdit.id = newIdsMap[addTreeNodeInput.temporaryId];
      }
    });
  }

  private async executeChangeMutationsInChunks(
    changes: NodeTreeChangesManagerChangeWithChildren[],
    chunk = 5,
  ): Promise<void> {
    for (let i = 0; i < changes.length; i += chunk) {
      const settledResults = await Promise.allSettled(
        changes
          .slice(i, i + chunk)
          .map((change) =>
            this.getMutationPromiseFor(change).then(
              async (result) => await this.onChangeMutationSuccess(change, result),
            ),
          ),
      );
      this.changeMutationErrorResults.push(
        ...(settledResults.filter((settledResult) => settledResult.status === 'rejected') as PromiseRejectedResult[]),
      );
    }
  }

  private async save(): Promise<void> {
    if (!window.confirm(`Are you sure you want to continue?`)) {
      return;
    }
    try {
      await this.handleNewTechnicalContacts();
    } catch (e) {
      this.ADD_TOAST_MESSAGES({
        messages: [{ text: 'Could not handle technical contacts.', class: 'error' }],
      });
    }
    this.changeMutationErrorResults = [];
    await this.executeChangeMutationsInChunks(this.changesManager.getChangesWithChildren());
    if (this.changeMutationErrorResults.length > 0) {
      this.ADD_TOAST_MESSAGES({
        messages: [{ text: 'Errors were encountered when saving data.', class: 'error' }],
      });
      // eslint-disable-next-line no-console -- dont swallow errors
      console.error(this.changeMutationErrorResults);
      return;
    }

    this.saveChangesToOriginalState();

    this.changesManager.reset();

    this.ADD_TOAST_MESSAGES({
      messages: [{ text: 'Data was saved.', class: 'success' }],
    });
  }

  private saveChangesToOriginalState(): void {
    this.originalNodeList = cloneDeep(this.nodeList);
    if (this.originalNodeToEdit && this.nodeToEdit) {
      this.originalNodeToEdit = cloneDeep(this.nodeToEdit);
    }
  }

  private async runChangeMutations(
    onSuccessCallback: (
      change: NodeTreeChangesManagerChangeWithChildren,
      result: FetchResult<unknown>,
    ) => Promise<void>,
  ): Promise<void> {
    await Promise.all(
      this.changesManager
        .getChangesWithChildren()
        .map((change) =>
          this.getMutationPromiseFor(change).then(async (result) => await onSuccessCallback(change, result)),
        ),
    );
  }

  private *generateFlatAddTreeNodeInputs(
    change: NodeTreeChangesManagerAddTreeNodeInputWithChildren,
  ): Generator<NodeTreeChangesManagerAddTreeNodeInput> {
    yield {
      parentId: change.parentId,
      temporaryId: change.temporaryId,
      treeNode: change.treeNode,
    };
    for (const child of change.children ?? []) {
      yield* this.generateFlatAddTreeNodeInputs(child);
    }
  }

  private updateNodeListIds(nodeList: NodeListNode[], newIds: Record<string, string>): void {
    for (const node of nodeList) {
      node.id = newIds[node.id] ?? node.id;
      this.updateNodeListIds(node.children ?? [], newIds);
    }
  }

  private getMutationPromiseFor(change: NodeTreeChangesManagerChangeWithChildren): Promise<FetchResult> {
    switch (change.type) {
      case 'Add':
        return this.getAddNodeMutation(change.input);
      case 'Remove':
        return this.getRemoveNodeMutation(change.input);
      case 'Edit':
        return this.getEditNodeMutation(change.input);
      default:
        throw new Error();
    }
  }

  private getAddTreeNodeDataInput(
    input: NodeTreeChangesManagerAddTreeNodeInputWithChildren,
  ): AddTreeNodeInput['treeNode'] {
    return {
      ...omit(input.treeNode, ['editAfterAdd']),
      children: input.children?.map((child) => this.getAddTreeNodeDataInput(child)) ?? null,
    };
  }

  private getAddNodeMutation(
    input: NodeTreeChangesManagerAddTreeNodeInput,
  ): Promise<FetchResult<AppAdminCustomerManagePropertiesAddTreeNodeMutation>> {
    const variables: AppAdminCustomerManagePropertiesAddTreeNodeMutationVariables = {
      input: {
        parentId: input.parentId,
        treeNode: this.getAddTreeNodeDataInput(input),
      },
    };

    return this.$apollo.mutate<AppAdminCustomerManagePropertiesAddTreeNodeMutation>({
      mutation: addTreeNodeMutation,
      variables,
    });
  }

  private getRemoveNodeMutation(
    input: NodeTreeChangesManagerRemoveTreeNodeInput,
  ): Promise<FetchResult<AppAdminCustomerManagePropertiesRemoveTreeNodeMutation>> {
    const variables: AppAdminCustomerManagePropertiesRemoveTreeNodeMutationVariables = { input };

    return this.$apollo.mutate<AppAdminCustomerManagePropertiesRemoveTreeNodeMutation>({
      mutation: removeNodeMutation,
      variables,
    });
  }

  private getEditNodeMutation(input: NodeTreeChangesManagerEditTreeNodeInput): Promise<FetchResult> {
    const variables = { input: input.input };

    let mutation = undefined;

    switch (input.type) {
      case 'TreeNode':
        mutation = editTreeNodeMutation;
        break;
      case 'Directory':
        mutation = editDirectoryMutation;
        break;
      case 'PropertyGroup':
        mutation = editPropertyGroupMutation;
        break;
      case 'Property':
        mutation = editPropertyMutation;
        break;
      case 'PropertySubdivision':
        mutation = editPropertySubdivisionMutation;
        break;
    }

    return this.$apollo.mutate({
      mutation,
      variables,
    });
  }

  private propertiesImported(): void {
    //TODO: maybe there is a better solution to just reload the whole page (maybe reload only the current nodes)
    location.reload();
  }

  private async handleNewTechnicalContacts(): Promise<void> {
    const changes = this.changesManager.getChanges();
    let technicalContacts: AddUserInput[] = [];

    changes.forEach((change) => {
      if (change.type === 'Add') {
        const technicalContact =
          (change.input.treeNode.editAfterAdd as NodeTreeChangesManagerEditAfterAddPropertyInput)
            ?.technicalContactData ?? undefined;
        if (technicalContact && !technicalContact.id) {
          technicalContacts.push(technicalContact as AddUserInput);
        }
      } else if (change.type === 'Edit') {
        const technicalContact =
          (change.input as NodeTreeChangesManagerEditPropertyInput)?.technicalContactData ?? undefined;
        if (technicalContact && !technicalContact.id) {
          technicalContacts.push(technicalContact as AddUserInput);
        }
      }
    });

    technicalContacts = uniqBy(technicalContacts, 'email');

    //get existing users based on the emails
    const existingUserIds = await this.getUserIdsByEmailList(technicalContacts.map((user) => user.email));

    //create users for the contacts still without id
    const newUsersIds = await this.createNewUsers(
      technicalContacts.filter((user, index) => existingUserIds[index] === null),
    );

    const emailIdMap = technicalContacts.map((technicalContact, index) => ({
      email: technicalContact.email,
      id: existingUserIds[index] ?? newUsersIds[index],
    })) as { email: string; id: string }[];

    changes.forEach((change) => {
      if (change.type === 'Add') {
        const treeNode = change.input.treeNode;
        const technicalContact =
          (treeNode.editAfterAdd as NodeTreeChangesManagerEditAfterAddPropertyInput)?.technicalContactData ?? undefined;
        if (technicalContact && treeNode.editAfterAdd) {
          const newTechnicalContactId = emailIdMap.find((emailId) => emailId.email === technicalContact.email)?.id;
          if (newTechnicalContactId) {
            treeNode.editAfterAdd.input.technicalContactId = newTechnicalContactId;
          }
        }
      } else if (change.type === 'Edit') {
        const technicalContact =
          (change.input as NodeTreeChangesManagerEditPropertyInput)?.technicalContactData ?? undefined;
        if (technicalContact) {
          const newTechnicalContactId = emailIdMap.find((emailId) => emailId.email === technicalContact.email)?.id;
          if (newTechnicalContactId && change.input.type !== 'TreeNode') {
            change.input.input.technicalContactId = emailIdMap.find(
              (emailId) => emailId.email === technicalContact.email,
            )?.id;
          }
        }
      }
    });
  }

  private async getUserIdsByEmailList(emails: string[]): Promise<(string | null)[]> {
    return await Promise.all(
      emails.map((email) => {
        return this.$apollo
          .mutate<
            AppAdminCustomerManagePropertiesGetUserByEmailQuery,
            AppAdminCustomerManagePropertiesGetUserByEmailQueryVariables
          >({
            mutation: getUserByEmailQuery,
            variables: {
              email,
            },
          })
          .then((result) => (result.data ? result.data.users.first.id : null))
          .catch(() => null);
      }),
    );
  }

  private async createNewUsers(userInputs: AddUserInput[]): Promise<(string | null)[]> {
    const results = await Promise.all(
      userInputs.map((user) => {
        return this.$apollo
          .mutate<AppAdminAddUserMutation, AppAdminAddUserMutationVariables>({
            mutation: addUserMutation,
            variables: {
              input: {
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
              },
            },
          })
          .catch(() => null);
      }),
    );
    return results.map((result) => result?.data?.addUser.id ?? null);
  }

  @Provide(NODE_LIST_ON_NODE_CLICK)
  public async nodeListOnNodeClick(node: NodeListNode): Promise<void> {
    if (!node.open) {
      await this.toggleNodeListNode(node);
    }
    await this.nodeListEditNode(node);
    this.nodeList = getNodeListWithChanges(this.nodeList, this.originalNodeList, this.customerId, this.changesManager);
  }

  @Provide(NODE_LIST_ON_NODE_TOGGLE)
  public async nodeListOnNodeToggle(node: NodeListNode): Promise<void> {
    await this.toggleNodeListNode(node);
    this.nodeList = getNodeListWithChanges(this.nodeList, this.originalNodeList, this.customerId, this.changesManager);
  }

  @Provide(NODE_LIST_REMOVE_NODE)
  public nodeListRemoveNode(parentNode: NodeListNode | undefined, childNode: NodeListNode): void {
    if (!window.confirm(`Are you sure you want to remove the node with name: ${childNode.name}?`)) {
      return;
    }

    this.changesManager.removeNode(childNode.id);
    this.nodeList = getNodeListWithChanges(this.nodeList, this.originalNodeList, this.customerId, this.changesManager);
  }

  @Provide(NODE_LIST_ADD_NODE)
  public async nodeListAddNode(parentNode: NodeListNode | undefined, childNode: NodeListNode): Promise<void> {
    if (parentNode && (parentNode.type === 'Directory' || parentNode.type === 'PropertyGroup') && !parentNode.open) {
      await this.toggleNodeListNode(parentNode);
    }

    this.changesManager.addNode({
      parentId: parentNode?.id ?? this.customerId,
      temporaryId: childNode.id,
      treeNode: {
        type: childNode.type as TreeNodeType,
        name: childNode.name,
      },
    });
    this.nodeList = getNodeListWithChanges(this.nodeList, this.originalNodeList, this.customerId, this.changesManager);
  }

  private async getDirectoryData(nodeListNode: NodeListNode): Promise<Directory> {
    const change = this.changesManager.get(nodeListNode.id);
    if (change && change.type === 'Add') {
      return this.getEmptyDirectory(nodeListNode);
    } else {
      const { data } = await this.$apollo.query<
        AppAdminCustomerManagePropertiesNodeEditorDirectoryEditContentQuery,
        AppAdminCustomerManagePropertiesNodeEditorDirectoryEditContentQueryVariables
      >({ query: directoryEditContentQuery, variables: { id: nodeListNode.id }, fetchPolicy: 'no-cache' });
      return data.treeNodes.first as Directory;
    }
  }

  private async getPropertyGroupData(nodeListNode: NodeListNode): Promise<PropertyGroup> {
    const change = this.changesManager.get(nodeListNode.id);
    if (change && change.type === 'Add') {
      return this.getEmptyPropertyGroup(nodeListNode);
    } else {
      const { data } = await this.$apollo.query<
        AppAdminCustomerManagePropertiesNodeEditorPropertyGroupEditContentQuery,
        AppAdminCustomerManagePropertiesNodeEditorPropertyGroupEditContentQueryVariables
      >({ query: propertyGroupEditContentQuery, variables: { id: nodeListNode.id }, fetchPolicy: 'no-cache' });
      return data.treeNodes.first as PropertyGroup;
    }
  }

  private async getPropertyData(nodeListNode: NodeListNode): Promise<Property> {
    const change = this.changesManager.get(nodeListNode.id);
    if (change && change.type === 'Add') {
      return this.getEmptyProperty(nodeListNode);
    } else {
      const { data } = await this.$apollo.query<
        AppAdminCustomerManagePropertiesNodeEditorPropertyEditContentQuery,
        AppAdminCustomerManagePropertiesNodeEditorPropertyEditContentQueryVariables
      >({ query: propertyEditContentQuery, variables: { id: nodeListNode.id }, fetchPolicy: 'no-cache' });
      return data.treeNodes.first as Property;
    }
  }

  private getEmptyDirectory(nodeListNode: NodeListNode): Directory {
    return {
      __typename: 'Directory',
      id: nodeListNode.id,
      name: nodeListNode.name,
      order: 0,
      externalId: null,
      technicalContact: null,
    };
  }

  private getEmptyPropertyGroup(nodeListNode: NodeListNode): PropertyGroup {
    return {
      __typename: 'PropertyGroup',
      id: nodeListNode.id,
      name: nodeListNode.name,
      order: 0,
      floors: [],
      bgswid: null,
      externalId: null,
      technicalContact: null,
      children: {
        items: [],
      },
      contract: this.contracts[0],
      classification: null,
    };
  }

  private getEmptyProperty(nodeListNode: NodeListNode): Property {
    return {
      __typename: 'Property',
      id: nodeListNode.id,
      name: nodeListNode.name,
      order: 0,
      floors: [],
      address: null,
      bgswid: null,
      externalId: null,
      technicalContact: null,
      location: null,
      children: {
        items: [],
      },
      contract: this.contracts[0],
      classification: null,
    };
  }

  @Provide(NODE_LIST_EDIT_NODE)
  public async nodeListEditNode(node: NodeListNode): Promise<void> {
    this.originalNodeToEdit = await (node.type === 'Directory'
      ? this.getDirectoryData(node)
      : node.type === 'PropertyGroup'
      ? this.getPropertyGroupData(node)
      : this.getPropertyData(node));
    this.nodeToEdit = getNodeEditorNodeWithChanges(
      cloneDeep(this.originalNodeToEdit),
      this.changesManager,
      this.contracts,
    );
  }

  @Provide(NODE_LIST_EDIT_NODE_ORDER)
  public async nodeListEditNodeOrder(node: NodeListNode, order: number): Promise<void> {
    this.changesManager.editNode({ type: 'TreeNode', input: { id: node.id, name: node.name, order } });
    this.nodeList = getNodeListWithChanges(this.nodeList, this.originalNodeList, this.customerId, this.changesManager);
  }

  @Provide(NODE_EDITOR_ADD_PROPERTY_SUBDIVISION)
  public async nodeEditorAddPropertySubdivision(propertySubdivision: PropertySubdivision): Promise<void> {
    if (!this.originalNodeToEdit) {
      return;
    }
    this.changesManager.addNode({
      temporaryId: propertySubdivision.id,
      parentId: this.originalNodeToEdit.id,
      treeNode: {
        type: propertySubdivision.__typename as TreeNodeType,
        name: propertySubdivision.name,
        order: propertySubdivision.order,
        floorLevel: propertySubdivision.floorLevel,
        editAfterAdd: {
          input: {
            name: propertySubdivision.name,
            order: propertySubdivision.order,
            floorLevel: propertySubdivision.floorLevel,
            visualizationHint: propertySubdivision.visualizationHint,
            rooms: propertySubdivision.rooms,
            position: propertySubdivision.position,
            size: propertySubdivision.size,
            externalType: propertySubdivision.externalType,
            externalId: propertySubdivision.externalId,
          },
        },
      },
    });
    this.nodeToEdit = getNodeEditorNodeWithChanges(
      cloneDeep(this.originalNodeToEdit),
      this.changesManager,
      this.contracts,
    );
  }

  @Provide(NODE_EDITOR_REMOVE_PROPERTY_SUBDIVISION)
  public async nodeEditorRemovePropertySubdivision(id: string): Promise<void> {
    if (!this.originalNodeToEdit) {
      return;
    }
    this.changesManager.removeNode(id);
    this.nodeToEdit = getNodeEditorNodeWithChanges(
      cloneDeep(this.originalNodeToEdit),
      this.changesManager,
      this.contracts,
    );
  }

  @Provide(NODE_EDITOR_EDIT_NODE)
  public async nodeEditorEditNode(node: NodeTreeChangesManagerEditTreeNodeInput, contractId?: string): Promise<void> {
    if (!this.originalNodeToEdit) {
      return;
    }
    const change = this.changesManager.get(node.input.id);
    if (change && change.type === 'Add') {
      const inputWithoutId = { ...node, input: omit(node.input, 'id') };
      this.changesManager.addNode({
        ...change.input,
        treeNode: { ...change.input.treeNode, name: node.input.name, contractId, editAfterAdd: inputWithoutId },
      });
    } else {
      this.changesManager.editNode(node);
    }
    this.nodeList = getNodeListWithChanges(this.nodeList, this.originalNodeList, this.customerId, this.changesManager);
    this.nodeToEdit = getNodeEditorNodeWithChanges(
      cloneDeep(this.originalNodeToEdit),
      this.changesManager,
      this.contracts,
    );
  }

  private get canEditContract(): boolean {
    if (!this.nodeToEdit) {
      return false;
    }
    const change = this.changesManager.get(this.nodeToEdit.id);
    return change !== undefined && change.type === 'Add';
  }
}
