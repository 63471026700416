


























import { PaginationQueryStringMixin } from '@/features/core/components/mixins/pagination-query-string';
import { Action, RootAction } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';
import { Component, Mixins } from 'vue-property-decorator';
import query from './view.gql';
import {
  AppAdminFlatCodesViewQuery,
  AppAdminFlatCodesViewQueryVariables,
} from './__generated__/AppAdminFlatCodesViewQuery';
import { ORIGIN_ENDPOINT } from '@/env';
import { keycloak } from '@/keycloak';
import { FlatCodeRow } from '@/features/app-admin/components/flat-code-table/model';
import {
  AppAdminFlatCodesViewRemoveMutation,
  AppAdminFlatCodesViewRemoveMutationVariables,
} from '../../flat-code/__generated__/AppAdminFlatCodesViewRemoveMutation';

import removeMutation from '../../flat-code/remove.gql';

@Component({
  apollo: {
    me: {
      query,
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'ignore',
      variables(this: FlatCodesView): AppAdminFlatCodesViewQueryVariables {
        return { skip: this.skip, take: this.take };
      },
    },
  },
  data() {
    return {
      me: undefined,
      selectedFlatCodes: [],
    };
  },
})
export default class FlatCodesView extends Mixins(PaginationQueryStringMixin) {
  private readonly me?: AppAdminFlatCodesViewQuery['me'];

  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  private selectedFlatCodes!: FlatCodeRow[];

  protected get count(): number {
    return this.me?.flatCodes?.count ?? 0;
  }

  public async exportCodes(): Promise<void> {
    const response = await fetch(`${ORIGIN_ENDPOINT}/api/custom-export/my-flat-codes`, {
      method: 'GET',
      headers: {
        authorization: `Bearer ${keycloak?.token ?? ''}`,
      },
    });
    if (!response.ok) {
      return;
    }
    const fileName = response.headers
      .get('content-disposition')
      ?.split(';')
      ?.find((s) => s.trim().toLowerCase().startsWith('filename='))
      ?.replace(/["']/g, '')
      ?.trim()
      ?.split('filename=')?.[1];

    const blob = await response.blob();
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName ?? 'unnamed'); //or any other extension
    document.body.appendChild(link);
    link.click();
  }

  private get flatCodes(): AppAdminFlatCodesViewQuery['me']['flatCodes']['items'] {
    return this.me?.flatCodes?.items ?? [];
  }

  private get loading(): boolean {
    return this.$apollo.queries.me.loading;
  }

  private async removeSelectedFlatCodes(): Promise<void> {
    if (!window.confirm(`Do you want to remove selected flat codes?`)) {
      return;
    }

    const ids = this.selectedFlatCodes.map((flatCode) => flatCode.id);

    const { data } = await this.$apollo.mutate<
      AppAdminFlatCodesViewRemoveMutation,
      AppAdminFlatCodesViewRemoveMutationVariables
    >({
      mutation: removeMutation,
      variables: { input: { ids: ids } },
    });

    if (!data) {
      throw new Error(`Couldn't delete flat code`);
    } else {
      if (ids.length !== data.removeFlatCodes.affected) {
        this.ADD_TOAST_MESSAGES({
          messages: [
            {
              text: `${
                ids.length - data.removeFlatCodes.affected
              } Codes waren noch in Benutzung und konnten daher nicht gelöscht werden!`,
              class: 'warning',
            },
          ],
        });
      }
    }

    this.selectedFlatCodes = [];
    this.$apollo.queries.me.refetch();
  }

  private onFlatCodesAdded(): void {
    this.$apollo.queries.me.refetch();
    this.ADD_TOAST_MESSAGES({
      messages: [{ text: 'Flat codes erstellt.', class: 'success' }],
    });
  }
}
