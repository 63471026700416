
















import InputSelect from '@/features/ui/inputs/InputSelect.global.vue';
import { Input } from '@/features/ui/inputs/model';
import { IntegerProp, BooleanProp, StringProp } from '@/util/prop-decorators';
import { Vue, Component, Model } from 'vue-property-decorator';
import { AppAdminInputSelectContractControlContractFragment } from './__generated__/AppAdminInputSelectContractControlContractFragment';
import {
  AppAdminInputSelectContractControlQuery,
  AppAdminInputSelectContractControlQueryVariables,
} from './__generated__/AppAdminInputSelectContractControlQuery';
import query from './query.gql';
import { ApolloQueryResult } from 'apollo-client';

@Component({
  apollo: {
    customers: {
      query,
      fetchPolicy: 'no-cache',
      variables(this: InputSelectContractControl): AppAdminInputSelectContractControlQueryVariables {
        return { customerId: this.customerId, take: this.take };
      },
      result(
        this: InputSelectContractControl,
        { data }: ApolloQueryResult<AppAdminInputSelectContractControlQuery>,
      ): void {
        this.contracts = data.customers.first.contracts;
      },
    },
  },
  data() {
    return { contracts: undefined };
  },
})
export default class InputSelectContractControl extends Vue implements Input {
  @StringProp(true)
  private readonly customerId!: string;

  @Model('update')
  private readonly value?: AppAdminInputSelectContractControlContractFragment;

  @IntegerProp(10, 1)
  private readonly take!: number;

  @BooleanProp()
  private readonly disabled!: boolean;

  public $refs!: { input: InputSelect };

  private contracts?: AppAdminInputSelectContractControlQuery['customers']['first']['contracts'];

  private get model(): AppAdminInputSelectContractControlContractFragment | undefined {
    return this.value;
  }

  private set model(value: AppAdminInputSelectContractControlContractFragment | undefined) {
    this.$emit('update', value);
  }

  public get normalizedValue(): AppAdminInputSelectContractControlContractFragment | undefined {
    return this.value;
  }

  public get empty(): boolean {
    return this.$refs.input.empty;
  }

  public get pristine(): boolean {
    return this.$refs.input.pristine;
  }
}
