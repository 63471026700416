











































import { Action, RootAction } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';
import { getPrimaryTreeNode, getSecondaryTreeNode } from '@/features/domain-ui/tree-node-path/util';
import { Column } from '@/features/ui/table/model';
import { ArrayProp } from '@/util/prop-decorators';
import { Component, ModelSync, Vue } from 'vue-property-decorator';
import { FlatCodeRow } from './model';
import { AppAdminFlatCodeTableFlatCodeFragment_resource } from './__generated__/AppAdminFlatCodeTableFlatCodeFragment';

const isExpired: (date: Date) => boolean = (date) => {
  return date < new Date();
};

@Component({
  data() {
    return {
      isExpired,
    };
  },
})
export default class FlatCodeTable extends Vue {
  @ArrayProp(() => [])
  private readonly flatCodes!: FlatCodeRow[];

  @ModelSync('selectedFlatCodes', 'update:selectedFlatCodes', { type: Array, default: [] })
  private modelSelectedFlatCodes!: FlatCodeRow[];

  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  private readonly columns: Column[] = [
    { name: 'object', field: 'resource', label: 'Objekt', width: 280, verticalAlign: 'top' },
    { name: 'flat', field: 'resource', label: 'Wohnung', verticalAlign: 'top' },
    { name: 'users', field: 'users.items', label: 'Nutzername', width: 280, verticalAlign: 'top' },
    { name: 'status', field: 'users.items', label: 'Status', verticalAlign: 'top' },
    {
      name: 'expirationDate',
      accessor: ({ expirationDate }: FlatCodeRow) => expirationDate,
      label: 'Ablaufdatum',
      verticalAlign: 'top',
    },
    {
      name: 'accessDate',
      field: 'accessDate',
      label: 'Zugriffsdatum',
    },
    { name: 'value', label: 'Code', verticalAlign: 'top' },
    { name: 'actions', label: '', verticalAlign: 'top' },
  ];

  private copyToClipboard(row: FlatCodeRow): void {
    navigator.clipboard.writeText(`${window.location.origin}/eed-reg/${row.value}`);
    this.ADD_TOAST_MESSAGES({
      messages: [{ text: 'In die Zwischenablage kopiert', class: 'success' }],
    });
  }

  private getFlatCode(value: AppAdminFlatCodeTableFlatCodeFragment_resource): string | undefined {
    const treeNodes = value.path.items;
    const secondaryTreeNode = getSecondaryTreeNode(treeNodes, getPrimaryTreeNode(treeNodes));
    return secondaryTreeNode?.name;
  }

  private goToFlatCode(row: FlatCodeRow): void {
    this.$router.push({ name: 'AppAdmin/FlatCode', params: { flatCodeId: row.id } });
  }
}
