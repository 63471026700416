

































import { Action, RootAction } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';
import { EditFlatCodeInput } from '@/types/iot-portal';
import { StringProp } from '@/util/prop-decorators';
import { ApolloQueryResult } from '@@/node_modules/apollo-client';
import { Component, Vue } from 'vue-property-decorator';
import editMutation from './edit.gql';
import removeMutation from './remove.gql';
import query from './view.gql';
import {
  AppAdminFlatCodeViewEditMutation,
  AppAdminFlatCodeViewEditMutationVariables,
} from './__generated__/AppAdminFlatCodeViewEditMutation';
import {
  AppAdminFlatCodeViewQuery,
  AppAdminFlatCodeViewQueryVariables,
} from './__generated__/AppAdminFlatCodeViewQuery';
import {
  AppAdminFlatCodesViewRemoveMutation,
  AppAdminFlatCodesViewRemoveMutationVariables,
} from './__generated__/AppAdminFlatCodesViewRemoveMutation';

type FormData = Omit<EditFlatCodeInput, 'id'>;

@Component({
  apollo: {
    view: {
      query,
      fetchPolicy: 'no-cache',
      variables(this: FlatCodeView): AppAdminFlatCodeViewQueryVariables {
        return { id: this.flatCodeId };
      },
      manual: true,
      result(this: FlatCodeView, { data }: ApolloQueryResult<AppAdminFlatCodeViewQuery>): void {
        this.flatCode = data.flatCode.first;
      },
    },
  },
  data() {
    return { flatCode: undefined };
  },
})
export default class FlatCodeView extends Vue {
  @StringProp(true)
  private readonly flatCodeId!: string;

  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  private flatCode?: AppAdminFlatCodeViewQuery['flatCode']['first'];

  private async editFlatCode({ accessDate }: FormData): Promise<void> {
    const { data } = await this.$apollo.mutate<
      AppAdminFlatCodeViewEditMutation,
      AppAdminFlatCodeViewEditMutationVariables
    >({
      mutation: editMutation,
      variables: { input: { id: this.flatCodeId, accessDate } },
    });

    if (!data) {
      throw new Error('Flat Code konnte nicht gespeichert werden.');
    }

    this.ADD_TOAST_MESSAGES({
      messages: [{ text: 'Flat code edited', class: 'success' }],
    });

    this.$apollo.queries.view.refetch();
  }

  private async removeFlatCode(): Promise<void> {
    if (!window.confirm(`Do you want to remove the flat code?`)) {
      return;
    }

    const { data } = await this.$apollo.mutate<
      AppAdminFlatCodesViewRemoveMutation,
      AppAdminFlatCodesViewRemoveMutationVariables
    >({
      mutation: removeMutation,
      variables: { input: { ids: [this.flatCodeId] } },
    });

    if (!data) {
      throw new Error(`Couldn't delete flat code`);
    }

    this.$router.push({ name: 'AppAdmin/FlatCodes' });
  }
}
