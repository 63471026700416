import { TimeframeType, ExportPresetType } from '@/types/iot-portal';

interface TimeFrameMeta {
  value: TimeframeType;
  label: string;
}

export const TIMEFRAME_META: { readonly [k in TimeframeType]: Readonly<TimeFrameMeta> } = Object.freeze({
  [TimeframeType.YEARLY]: {
    value: TimeframeType.YEARLY,
    label: 'Jährlich',
  },
  [TimeframeType.MONTHLY]: {
    value: TimeframeType.MONTHLY,
    label: 'Monatlich',
  },
  [TimeframeType.WEEKLY]: {
    value: TimeframeType.WEEKLY,
    label: 'Wöchentlich',
  },
  [TimeframeType.CUSTOM]: {
    value: TimeframeType.CUSTOM,
    label: 'Angepasst',
  },
  [TimeframeType.QUARTER_HOURLY]: {
    value: TimeframeType.QUARTER_HOURLY,
    label: 'Viertelstündlich',
  },
});

interface ExportPresetTypesMeta {
  value: ExportPresetType;
  label: string;
}

export const EXPORT_PRESET_TYPES_META: { readonly [k in ExportPresetType]: Readonly<ExportPresetTypesMeta> } =
  Object.freeze({
    [ExportPresetType.DEFAULT]: {
      value: ExportPresetType.DEFAULT,
      label: 'DEFAULT',
    },
    [ExportPresetType.STEFFENS]: {
      value: ExportPresetType.STEFFENS,
      label: 'STEFFENS',
    },
  });
