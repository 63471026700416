var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-wizard',{attrs:{"id":"csv-import","title":"Import from CSV"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._t("default",null,null,props)]}},{key:"content",fn:function(ref){
var hide = ref.hide;
return [_c('ui-form',{attrs:{"initial-data":{ contractId: _vm.contracts.length ? _vm.contracts[0].id : undefined },"action":_vm.applyAction},on:{"submitted":hide},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var formData = ref.formData;
var dirty = ref.dirty;
var busy = ref.busy;
var submit = ref.submit;
var errorMessages = ref.errorMessages;
return [_c('core-toast',{attrs:{"messages":errorMessages,"error":""}}),_c('ui-flex',[_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-field',{attrs:{"input":"ui-input-file","path":"file","accept":'.csv',"label":"Select a CSV file to import properties."}})],1),_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-field',{attrs:{"input":"ui-input-select","path":"contractId","label":"Contract","option-label":"externalId","reduce":function (ref) {
	var id = ref.id;

	return id;
},"options":_vm.contracts}})],1),_c('ui-cell',{attrs:{"grow":0,"push-right":""}},[_c('ui-wizard-button',{attrs:{"disabled":!dirty || !_vm.isValid(formData),"busy":busy},on:{"click":submit}},[_vm._v("Select")])],1)],1)]}}],null,true)})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }