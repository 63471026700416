















































import { AddFlatCodesResourceInput, PropertySubdivisionVisualizationHint, TreeNodeType } from '@/types/iot-portal';
import { isArray, isString } from '@/util/lang';
import { NIL } from 'uuid';
import { Component, Vue } from 'vue-property-decorator';
import { RESOURCE_TYPE_TREE_NODE } from '../../views/user/permissions/PermissionsView.vue';
import addFlatCodesMutation from './add-flat-codes.gql';
import customersQuery from './customers.gql';
import treeNodesQuery from './tree-nodes.gql';
import { AppAdminAddFlatCodesWizardControlCustomersQuery } from './__generated__/AppAdminAddFlatCodesWizardControlCustomersQuery';
import {
  AppAdminAddFlatCodesWizardControlMutation,
  AppAdminAddFlatCodesWizardControlMutationVariables,
} from './__generated__/AppAdminAddFlatCodesWizardControlMutation';
import {
  AppAdminAddFlatCodesWizardControlTreeNodesQuery,
  AppAdminAddFlatCodesWizardControlTreeNodesQueryVariables,
} from './__generated__/AppAdminAddFlatCodesWizardControlTreeNodesQuery';

type FlatCodes = AppAdminAddFlatCodesWizardControlMutation['addFlatCodes']['flatCodes'];
type Customer = AppAdminAddFlatCodesWizardControlCustomersQuery['customers']['items'][number];
type FormData = { treeNodeIds: string[]; accessDate: Date | null };

@Component({
  apollo: {
    customers: {
      query: customersQuery,
      fetchPolicy: 'cache-and-network',
    },
  },
  data() {
    return { customers: undefined, selectedCustomer: undefined };
  },
})
export default class AddFlatCodesWizardControl extends Vue {
  private customers?: AppAdminAddFlatCodesWizardControlCustomersQuery['customers'];

  private selectedCustomer?: Customer;

  private get rootIds(): string[] {
    return [this.selectedCustomer?.rootDirectory.id ?? NIL];
  }

  private get selectableTypes(): TreeNodeType[] {
    return [
      TreeNodeType.RootDirectory,
      TreeNodeType.Directory,
      TreeNodeType.PropertyGroup,
      TreeNodeType.Property,
      TreeNodeType.PropertySubdivision,
    ];
  }

  private get filter(): (T: AppAdminAddFlatCodesWizardControlTreeNodesQuery['treeNodes']['items'][number]) => boolean {
    return (treeNode) => {
      if (treeNode.__typename !== TreeNodeType.PropertySubdivision) {
        return true;
      }

      return treeNode.visualizationHint !== PropertySubdivisionVisualizationHint.STAIRWELL;
    };
  }

  private async addFlatCodes({ treeNodeIds, accessDate }: FormData): Promise<void> {
    const { data } = await this.$apollo.query<
      AppAdminAddFlatCodesWizardControlTreeNodesQuery,
      AppAdminAddFlatCodesWizardControlTreeNodesQueryVariables
    >({
      query: treeNodesQuery,
      variables: {
        ids: treeNodeIds,
      },
    });

    const addFlatCodesInput: AddFlatCodesResourceInput[] = data.treeNodes.items
      .filter((tree) =>
        tree.__typename === 'PropertySubdivision'
          ? tree.visualizationHint === PropertySubdivisionVisualizationHint.APARTMENT
          : true,
      )
      .map(({ id }) => ({
        resource: id,
        resourceType: RESOURCE_TYPE_TREE_NODE,
        accessDate,
      }));

    if (addFlatCodesInput.length === 0) {
      throw new Error('An diesen Orten wurden keine Wohnungen gefunden');
    }

    await this.createFlatCodes(addFlatCodesInput);
  }

  private async createFlatCodes(input: AddFlatCodesResourceInput[]): Promise<FlatCodes> {
    const { data } = await this.$apollo.mutate<
      AppAdminAddFlatCodesWizardControlMutation,
      AppAdminAddFlatCodesWizardControlMutationVariables
    >({
      mutation: addFlatCodesMutation,
      variables: {
        input: { resources: input },
      },
    });

    if (!data) {
      throw new Error('Die uVI Codes konnte nicht angelegt werden!');
    }

    return data.addFlatCodes.flatCodes;
  }

  private isNonEmptyString(value: unknown): boolean {
    return isString(value) && value.length > 0;
  }

  private isValidData({ treeNodeIds }: Partial<FormData>): boolean {
    return isArray(treeNodeIds);
  }

  private pluckId<T>(objects: { id: T }[] | null): T[] | null {
    return objects?.map(({ id }) => id) ?? null;
  }

  private async onSubmitted(flatCodes: FlatCodes, hide: () => Promise<void>): Promise<void> {
    await hide();

    this.$emit('flat-codes-added', flatCodes);
  }
}
