




import { PaginationQueryStringMixin } from '@/features/core/components/mixins/pagination-query-string';
import { Component, Mixins } from 'vue-property-decorator';
import DataConsumptionPollPanel from '@/features/app-customer/components/data-consumption-poll-panel/DataConsumptionPollPanel.vue';

@Component({
  components: { DataConsumptionPollPanel },
})
export default class DataConsumptionPollAdminView extends Mixins(PaginationQueryStringMixin) {}
