











import { Column } from '@/features/ui/table/model';
import { ArrayProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import { GroupRow } from './model';

@Component
export default class GroupTable extends Vue {
  @ArrayProp(() => [])
  private readonly groups!: GroupRow[];

  private readonly columns: Column[] = [
    { name: 'name', label: 'Name' },
    { name: 'roles', label: 'Roles' },
    { name: 'permissions', label: 'Permissions' },
    { name: 'actions', label: '' },
  ];
}
