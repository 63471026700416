


























import { StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import {
  AppAdminContractViewQuery,
  AppAdminContractViewQueryVariables,
} from './__generated__/AppAdminContractViewQuery';
import query from './view.gql';

@Component({
  apollo: {
    contract: {
      query,
      fetchPolicy: 'no-cache',
      variables(this: AppAdminContractView): AppAdminContractViewQueryVariables {
        return { id: this.contractId };
      },
    },
  },
  data() {
    return { contract: undefined };
  },
})
export default class AppAdminContractView extends Vue {
  @StringProp(true)
  private readonly contractId!: string;

  private readonly contract?: AppAdminContractViewQuery['contract'];
}
