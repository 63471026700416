













































































import DeviceRoleMapMixin from '@/features/core/components/mixins/device-role-map';
import { PaginationQueryStringMixin } from '@/features/core/components/mixins/pagination-query-string';
import { Column } from '@/features/ui/table/model';
import { AddAlertRuleInput } from '@/types/iot-portal';
import { Component, Mixins } from 'vue-property-decorator';
import addAlertRuleMutation from './add-alert-rule.gql';
import query from './view.gql';
import {
  AppAdminAlertRulesViewAddAlertRuleMutation,
  AppAdminAlertRulesViewAddAlertRuleMutationVariables,
} from './__generated__/AppAdminAlertRulesViewAddAlertRuleMutation';
import {
  AppAdminAlertRulesViewQuery,
  AppAdminAlertRulesViewQueryVariables,
} from './__generated__/AppAdminAlertRulesViewQuery';

@Component({
  apollo: {
    alertRules: {
      query,
      fetchPolicy: 'no-cache',
      variables(this: AlertRulesView): AppAdminAlertRulesViewQueryVariables {
        return { skip: this.skip, take: this.take };
      },
    },
  },
  data() {
    return { alertRules: undefined };
  },
})
export default class AlertRulesView extends Mixins(DeviceRoleMapMixin, PaginationQueryStringMixin) {
  private readonly alertRules?: AppAdminAlertRulesViewQuery['alertRules'];

  private readonly columns: Column[] = [
    { name: 'name' },
    { name: 'type', width: 350 },
    { name: 'enabled' },
    { name: 'action', label: '', align: 'right' },
  ];

  protected get count(): number {
    return this.alertRules?.count ?? 0;
  }

  private applicableSpotRoleListOf(rule: AppAdminAlertRulesViewQuery['alertRules']['items'][number]): string[] {
    return rule.applicableSpotRoles
      .map((role) => this.deviceRoleMap[role]?.shortLabel ?? role)
      .sort((a, b) => a.localeCompare(b));
  }

  private async addAlertRule(input: AddAlertRuleInput): Promise<string> {
    const { data } = await this.$apollo.mutate<
      AppAdminAlertRulesViewAddAlertRuleMutation,
      AppAdminAlertRulesViewAddAlertRuleMutationVariables
    >({
      mutation: addAlertRuleMutation,
      variables: { input },
    });

    if (!data) {
      throw new Error('Die Alarmregelvorlage konnte nicht hinzugefügt werden!');
    }

    return data.addAlertRule.alertRule.id;
  }

  private async onAlertRuleAdded(alertRuleId: string, hide: () => Promise<void>): Promise<void> {
    await hide();

    this.$router.push({ name: 'AppAdmin/AlertRule', params: { alertRuleId } });
  }
}
