var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-layout',{attrs:{"use":"core-layout-default"},scopedSlots:_vm._u([{key:"default",fn:function(){return [(_vm.exportPreset)?_c('ui-flex',[_c('ui-cell',{attrs:{"basis":1}},[_c('ui-content-header',{attrs:{"headline":_vm.exportPreset.name,"back-link":""},on:{"back-click":function($event){return _vm.$router.go(-1)}}},[_c('ui-breadcrumb-item',{attrs:{"to":{ name: 'AppAdmin' }}},[_vm._v("Administration")]),_vm._v(_vm._s(' ')),_c('ui-breadcrumb-item',{attrs:{"to":{ name: 'AppAdmin/Customers' }}},[_vm._v("Kunden")]),_vm._v(_vm._s(' ')),_c('ui-breadcrumb-item',{attrs:{"to":{ name: 'AppAdmin/Customer', params: { customerId: _vm.customerId } }}},[_vm._v(_vm._s(_vm.customer.first.name))]),_vm._v(_vm._s(' ')),_c('ui-breadcrumb-item',{attrs:{"to":{ name: 'AppAdmin/Customer/ExportPresets' }}},[_vm._v("Exportvorlagen")])],1)],1),_c('ui-cell',{attrs:{"basis":1}},[_c('ui-panel',[_c('ui-form',{attrs:{"initial-data":_vm.exportPreset,"action":_vm.editExportPreset},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var dirty = ref.dirty;
var busy = ref.busy;
var submit = ref.submit;
var errorMessages = ref.errorMessages;
return [_c('core-toast',{attrs:{"messages":errorMessages,"error":""}}),_c('ui-flex',[_c('ui-cell',{attrs:{"basis":0.5}},[_c('ui-form-field',{attrs:{"input":"ui-input-text","path":"name","label":"Name"}})],1),_c('ui-cell',{attrs:{"basis":0.5}},[_c('ui-form-field',{attrs:{"input":"ui-input-text","path":"mimeType","label":"MIME Type"}})],1),_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-field',{attrs:{"input":"ui-input-textarea","path":"query","label":"Query"}})],1),_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-field',{attrs:{"input":"ui-input-textarea","path":"template","label":"Template"}})],1),_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-field',{attrs:{"input":"ui-input-select","path":"exportPresetType","label":"Export Preset Type","options":_vm.exportPresetTypes,"reduce":function (ref) {
	var value = ref.value;

	return value;
},"clearable":false}})],1),_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-field',{attrs:{"input":"ui-input-checkbox-list","options":_vm.timeframeOptions,"path":"timeframes","label":"Zeitfensters"}})],1),_c('ui-cell',{attrs:{"basis":1}},[_c('ui-flex',[_c('ui-cell',{attrs:{"grow":0}},[_c('ui-form',{attrs:{"action":_vm.removeExportPreset,"no-reset":""},on:{"submitted":function($event){return _vm.onExportPresetRemoved()}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var busy = ref.busy;
var submit = ref.submit;
var errorMessages = ref.errorMessages;
return [_c('core-toast',{attrs:{"messages":errorMessages,"error":""}}),_c('ui-wizard-button',{attrs:{"secondary":"","busy":busy},on:{"click":submit}},[_vm._v("ExportPreset löschen")])]}}],null,true)})],1),_c('ui-cell',{attrs:{"grow":0,"push-right":""}},[_c('ui-wizard-button',{attrs:{"disabled":!dirty,"busy":busy},on:{"click":submit}},[_vm._v("Speichern")])],1)],1)],1)],1)]}}],null,false,4041025870)})],1)],1)],1):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }