


















import InputSelect from '@/features/ui/inputs/InputSelect.global.vue';
import { Input } from '@/features/ui/inputs/model';
import { IntegerProp, BooleanProp } from '@/util/prop-decorators';
import { Vue, Component, Model } from 'vue-property-decorator';
import { AppAdminInputSelectUserControlUserFragment } from './__generated__/AppAdminInputSelectUserControlUserFragment';
import {
  AppAdminInputSelectUserControlSearchQuery,
  AppAdminInputSelectUserControlSearchQueryVariables,
} from './__generated__/AppAdminInputSelectUserControlSearchQuery';
import query from './search.gql';

@Component({
  apollo: {
    users: {
      query,
      fetchPolicy: 'no-cache',
      skip(this: InputSelectUserControl): boolean {
        return !this.open;
      },
      variables(this: InputSelectUserControl): AppAdminInputSelectUserControlSearchQueryVariables {
        return { searchQuery: this.searchQuery, take: this.take };
      },
    },
  },
  data() {
    return { users: undefined };
  },
})
export default class InputSelectUserControl extends Vue implements Input {
  @Model('update')
  private readonly value?: AppAdminInputSelectUserControlUserFragment;

  @IntegerProp(10, 1)
  private readonly take!: number;

  @BooleanProp()
  private readonly disabled!: boolean;

  public $refs!: { input: InputSelect };

  private open = false;
  private searchQuery = '';
  private readonly users?: AppAdminInputSelectUserControlSearchQuery['users'];

  private get model(): AppAdminInputSelectUserControlUserFragment | undefined {
    return this.value;
  }

  private set model(value: AppAdminInputSelectUserControlUserFragment | undefined) {
    this.$emit('update', value);
  }

  public get normalizedValue(): AppAdminInputSelectUserControlUserFragment | undefined {
    return this.value;
  }

  public get empty(): boolean {
    return this.$refs.input.empty;
  }

  public get pristine(): boolean {
    return this.$refs.input.pristine;
  }
}
