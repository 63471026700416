



















import { PaginationQueryStringMixin } from '@/features/core/components/mixins/pagination-query-string';
import { Action, RootAction } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';
import { Component, Mixins } from 'vue-property-decorator';
import query from './view.gql';
import { AppAdminGroupsViewQuery, AppAdminGroupsViewQueryVariables } from './__generated__/AppAdminGroupsViewQuery';

@Component({
  apollo: {
    groups: {
      query,
      fetchPolicy: 'cache-and-network',
      variables(this: GroupsView): AppAdminGroupsViewQueryVariables {
        return { skip: this.skip, take: this.take };
      },
    },
  },
  data() {
    return {
      groups: undefined,
    };
  },
})
export default class GroupsView extends Mixins(PaginationQueryStringMixin) {
  private readonly groups?: AppAdminGroupsViewQuery['groups'];

  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  protected get count(): number {
    return this.groups?.count ?? 0;
  }

  private get loading(): boolean {
    return this.$apollo.queries.groups.loading;
  }

  private onGroupAdded(): void {
    this.$apollo.queries.groups.refetch();
    this.ADD_TOAST_MESSAGES({
      messages: [{ text: 'Group erstellt.', class: 'success' }],
    });
  }
}
