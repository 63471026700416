














































































import { Component, Inject } from 'vue-property-decorator';
import TextInput from '@/components/form/TextInput.vue';
import { AddUserInput, EditPropertyInput } from '@/types/iot-portal';
import NodeEditor from '../NodeEditor.vue';
import { get, omit, set } from 'lodash';
import PropertyBuildingEditor from './PropertyBuildingEditor.vue';
import { NodeEditorEditNode, NODE_EDITOR_EDIT_NODE } from '../model';

import { Property, PropertySubdivision } from './model';
import { BooleanProp, StringProp } from '@/util/prop-decorators';

//TODO improve templates in associated components

@Component({
  components: {
    TextInput,
    PropertyBuildingEditor,
  },
})
export default class PropertyEditor extends NodeEditor {
  @StringProp(true)
  private readonly customerId!: string;

  @BooleanProp(true)
  private readonly canEditContract!: boolean;

  private get property(): Property {
    return this.node as Property;
  }

  private get propertySubdivisions(): PropertySubdivision[] {
    return this.property.children.items ?? [];
  }

  private get latitude(): number | undefined {
    return get(this.property, 'location.latitude');
  }

  private set latitude(value: number | undefined) {
    set(this.property, 'location.latitude', value);
  }

  private get longitude(): number | undefined {
    return get(this.property, 'location.longitude');
  }

  private set longitude(value: number | undefined) {
    set(this.property, 'location.longitude', value);
  }

  private get street(): string | undefined {
    return get(this.property, 'address.street');
  }

  private set street(value: string | undefined) {
    set(this.property, 'address.street', value);
  }

  private get postal(): string | undefined {
    return get(this.property, 'address.postal');
  }

  private set postal(value: string | undefined) {
    set(this.property, 'address.postal', value);
  }

  private get locality(): string | undefined {
    return get(this.property, 'address.locality');
  }

  private set locality(value: string | undefined) {
    set(this.property, 'address.locality', value);
  }

  private get country(): string | undefined {
    return get(this.property, 'address.country');
  }

  private set country(value: string | undefined) {
    set(this.property, 'address.country', value);
  }

  private async onEdit(): Promise<void> {
    const input: EditPropertyInput = {
      ...omit(this.property, ['__typename', 'technicalContact', 'children', 'contract']),
      address: omit(this.property.address, '__typename'),
      floors: this.property.floors.map((floor) => omit(floor, '__typename')),
      location: this.property.location
        ? {
            longitude: Number(this.property.location.longitude),
            latitude: Number(this.property.location.latitude),
          }
        : null,
      technicalContactId: this.property.technicalContact?.id ?? null,
    };
    await this.nodeEditorEditNode(
      {
        type: 'Property',
        input,
        technicalContactData: (this.property.technicalContact as AddUserInput) ?? undefined,
      },
      this.property.contract.id,
    );
  }

  @Inject(NODE_EDITOR_EDIT_NODE)
  private nodeEditorEditNode!: NodeEditorEditNode;
}
