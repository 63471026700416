

























import { Component, Inject } from 'vue-property-decorator';
import TextInput from '@/components/form/TextInput.vue';
import NodeEditor from '../NodeEditor.vue';
import { NodeEditorEditNode, NODE_EDITOR_EDIT_NODE } from '../model';
import { AddUserInput, EditDirectoryInput } from '@/types/iot-portal';

import { Directory } from './model';

@Component({
  components: { TextInput },
})
export default class DirectoryEditor extends NodeEditor {
  private get directory(): Directory {
    return this.node as Directory;
  }

  private async onEdit(): Promise<void> {
    if (!this.directory) {
      return;
    }
    const input: EditDirectoryInput = {
      id: this.directory.id,
      name: this.directory.name,
      order: Number(this.directory.order),
      externalId: this.directory.externalId,
      technicalContactId: this.directory.technicalContact?.id ?? null,
    };
    await this.editNode({
      type: 'Directory',
      input,
      technicalContactData: (this.directory.technicalContact as AddUserInput) ?? undefined,
    });
  }

  @Inject(NODE_EDITOR_EDIT_NODE)
  private editNode!: NodeEditorEditNode;
}
