













































import { Action, RootAction } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';
import { EditGroupInput, GroupRole, GroupRolePermission } from '@/types/iot-portal';
import { StringProp } from '@/util/prop-decorators';
import { ApolloQueryResult } from '@@/node_modules/apollo-client';
import { Component, Vue } from 'vue-property-decorator';
import editMutation from './edit.gql';
import removeMutation from './remove.gql';
import query from './view.gql';
import {
  AppAdminGroupViewEditMutation,
  AppAdminGroupViewEditMutationVariables,
} from './__generated__/AppAdminGroupViewEditMutation';
import { AppAdminGroupViewQuery, AppAdminGroupViewQueryVariables } from './__generated__/AppAdminGroupViewQuery';
import {
  AppAdminGroupViewRemoveMutation,
  AppAdminGroupViewRemoveMutationVariables,
} from './__generated__/AppAdminGroupViewRemoveMutation';
import { Option } from '@/features/ui/inputs/model';

type EditFormInput = Omit<EditGroupInput, 'id'>;

@Component({
  apollo: {
    view: {
      query,
      fetchPolicy: 'no-cache',
      variables(this: GroupView): AppAdminGroupViewQueryVariables {
        return { id: this.groupId };
      },
      manual: true,
      result(this: GroupView, { data }: ApolloQueryResult<AppAdminGroupViewQuery>): void {
        this.group = data.group.first;
      },
    },
  },
  data() {
    return { group: undefined, emptyArray: [], roles: [] };
  },
})
export default class GroupView extends Vue {
  @StringProp(true)
  private readonly groupId!: string;

  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  private group?: AppAdminGroupViewQuery['group']['first'];
  private readonly emptyArray!: never[];

  private get roleOptions(): Option[] {
    return Object.values(GroupRole)
      .map((groupRole) => ({ value: groupRole, label: groupRole }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  private get rolePermissionOptions(): Option[] {
    return Object.values(GroupRolePermission)
      .map((groupRolePermission) => ({ value: groupRolePermission, label: groupRolePermission }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  private async editGroup({ name, roles, permissions }: EditFormInput): Promise<void> {
    const { data } = await this.$apollo.mutate<AppAdminGroupViewEditMutation, AppAdminGroupViewEditMutationVariables>({
      mutation: editMutation,
      variables: { input: { id: this.groupId, name, roles: roles ?? [], permissions: permissions ?? [] } },
    });

    if (!data) {
      throw new Error('Attributvorlage konnte nicht gespeichert werden.');
    }

    this.ADD_TOAST_MESSAGES({
      messages: [{ text: 'Group added', class: 'success' }],
    });

    this.$apollo.queries.view.refetch();
  }

  private async removeGroup(): Promise<void> {
    if (!window.confirm(`Do you want to remove the group?`)) {
      return;
    }

    const { data } = await this.$apollo.mutate<
      AppAdminGroupViewRemoveMutation,
      AppAdminGroupViewRemoveMutationVariables
    >({
      mutation: removeMutation,
      variables: { input: { id: this.groupId } },
    });

    if (!data) {
      throw new Error(`Couldn't delete group`);
    }

    this.$router.push({ name: 'AppAdmin/Groups' });
  }
}
