



















import { PaginationQueryStringMixin } from '@/features/core/components/mixins/pagination-query-string';
import { Component, Mixins } from 'vue-property-decorator';
import query from './view.gql';
import {
  AppAdminContractsViewQuery,
  AppAdminContractsViewQueryVariables,
} from './__generated__/AppAdminContractsViewQuery';

@Component({
  apollo: {
    contracts: {
      query,
      fetchPolicy: 'no-cache',
      variables(this: ContractsView): AppAdminContractsViewQueryVariables {
        return { skip: this.skip, take: this.take };
      },
    },
  },
  data() {
    return { contracts: undefined };
  },
})
export default class ContractsView extends Mixins(PaginationQueryStringMixin) {
  private readonly contracts?: AppAdminContractsViewQuery['contracts'];

  protected get count(): number {
    return this.contracts?.count ?? 0;
  }
}
