



























































































import { Action, RootAction } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';
import {
  ConfigureNotificationRetentionPolicyInput,
  EditCustomerInput,
  EditRootDirectoryInput,
} from '@/types/iot-portal';
import { StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import editCustomerMutation from './edit-customer.gql';
import query from './view.gql';
import {
  AppAdminCustomerGeneralViewEditCustomerMutation,
  AppAdminCustomerGeneralViewEditCustomerMutationVariables,
} from './__generated__/AppAdminCustomerGeneralViewEditCustomerMutation';
import {
  AppAdminCustomerGeneralViewQuery,
  AppAdminCustomerGeneralViewQueryVariables,
} from './__generated__/AppAdminCustomerGeneralViewQuery';

interface FormData {
  editCustomerInput: Omit<EditCustomerInput, 'id'>;
  editRootDirectoryInput: Omit<EditRootDirectoryInput, 'id'>;
  configureNotificationRetentionPolicyInput: Omit<ConfigureNotificationRetentionPolicyInput, 'customerId'>;
}

@Component({
  apollo: {
    customer: {
      query,
      fetchPolicy: 'network-only',
      variables(this: GeneralView): AppAdminCustomerGeneralViewQueryVariables {
        return { id: this.customerId };
      },
    },
  },
  data() {
    return { customer: undefined };
  },
})
export default class GeneralView extends Vue {
  @StringProp(true)
  private readonly customerId!: string;

  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  private customer?: AppAdminCustomerGeneralViewQuery['customer'];

  private async editCustomer({
    editCustomerInput,
    editRootDirectoryInput,
    configureNotificationRetentionPolicyInput,
  }: FormData): Promise<void> {
    if (this.customer === undefined) {
      return;
    }

    await this.$apollo.mutate<
      AppAdminCustomerGeneralViewEditCustomerMutation,
      AppAdminCustomerGeneralViewEditCustomerMutationVariables
    >({
      mutation: editCustomerMutation,
      variables: {
        editCustomerInput: { ...editCustomerInput, id: this.customerId },
        editRootDirectoryInput: { ...editRootDirectoryInput, id: this.customer.first.rootDirectory.id },
        configureNotificationRetentionPolicyInput: {
          ...configureNotificationRetentionPolicyInput,
          customerId: this.customerId,
        },
      },
    });

    this.ADD_TOAST_MESSAGES({
      messages: [{ text: 'Kunde gespeichert!', class: 'success' }],
    });
  }
}
