


































import UiButton from '@/components/clickables/Button.global.vue';
import { Component, Vue } from 'vue-property-decorator';
import { FunctionProp, ArrayProp } from '@/util/prop-decorators';
import { ApplyAction, Contract, FormData } from './model';

@Component({
  components: { UiButton },
})
export default class CsvSelectorModal extends Vue {
  @ArrayProp(true)
  private readonly contracts!: Contract[];

  @FunctionProp()
  private applyAction!: ApplyAction;

  private isValid({ file, contractId }: FormData): boolean {
    return file !== null && contractId !== null;
  }
}
