var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-panel',[_c('ui-flex',[_c('ui-cell',{attrs:{"grow":0,"push-right":""}},[_c('app-admin-add-customer-wizard-control',{on:{"customer-added":function($event){return _vm.$router.push({ name: 'AppAdmin/Customer', params: { customerId: $event.id } })}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var show = ref.show;
return [_c('ui-button',{attrs:{"front":"","icon":_vm.plusIcon},on:{"click":show}},[_vm._v("Kunde anlegen")])]}}])})],1),_c('ui-cell',{attrs:{"basis":1}},[_c('ui-table-skeleton',{attrs:{"ready":_vm.customers && !_vm.$apollo.queries.customers.loading,"column-count":4},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('ui-table',{attrs:{"columns":_vm.columns,"rows":_vm.customers ? _vm.customers.items : []},scopedSlots:_vm._u([{key:"externalId",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(value || '–'))]}},{key:"businessContact",fn:function(ref){
var value = ref.value;
return [(value === null)?[_vm._v("–")]:[_c('ui-clickable',{attrs:{"href":("mailto:" + (value.email))}},[_vm._v(_vm._s(value.name))])]]}},{key:"action",fn:function(ref){
var row = ref.row;
return [_c('ui-link',{attrs:{"to":{ name: 'AppAdmin/Customer', params: { customerId: row.id } }}},[_vm._v("Bearbeiten")])]}}])})]},proxy:true}])})],1),(_vm.totalPages > 1)?_c('ui-cell',{attrs:{"grow":0,"push-right":""}},[_c('ui-pagination',{attrs:{"total-pages":_vm.totalPages},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }