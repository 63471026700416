
















import { Component, Mixins } from 'vue-property-decorator';
import UiClickable from '@/components/clickables/Clickable.global.vue';
import Node from './Node.vue';
import NodeMenu from './NodeMenu.vue';

@Component({ components: { UiClickable, NodeMenu } })
export default class PropertyNode extends Mixins(Node) {
  private onItemClick(index: number): void {
    switch (index) {
      case 0:
        this.editNode(this.node);
        break;
      case 1:
        this.removeNode(this.parent, this.node);
        break;
    }
  }

  private onNodeClick(): void {
    this.onClick(this.node);
  }
}
