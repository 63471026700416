































import { StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import {
  AppAdminCustomerViewQuery,
  AppAdminCustomerViewQueryVariables,
} from './__generated__/AppAdminCustomerViewQuery';
import query from './view.gql';

@Component({
  apollo: {
    customer: {
      query,
      fetchPolicy: 'no-cache',
      variables(): AppAdminCustomerViewQueryVariables {
        return { id: this.customerId };
      },
    },
  },
  data() {
    return { customer: undefined };
  },
})
export default class CustomerView extends Vue {
  @StringProp(true)
  private readonly customerId!: string;

  private customer?: AppAdminCustomerViewQuery['customer'];
}
