










































































import { Action, RootAction } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';
import { Option } from '@/features/ui/inputs/model';
import { EditExportPresetInput } from '@/types/iot-portal';
import { StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import { TIMEFRAME_META, EXPORT_PRESET_TYPES_META } from './constants';
import editExportPresetMutation from './edit-export-preset.gql';
import removeExportPresetMutation from './remove-export-preset.gql';
import query from './view.gql';
import {
  AppAdminCustomerExportPresetViewEditExportPresetMutation,
  AppAdminCustomerExportPresetViewEditExportPresetMutationVariables,
} from './__generated__/AppAdminCustomerExportPresetViewEditExportPresetMutation';
import {
  AppAdminCustomerExportPresetViewQuery,
  AppAdminCustomerExportPresetViewQueryVariables,
} from './__generated__/AppAdminCustomerExportPresetViewQuery';
import {
  AppAdminCustomerExportPresetViewRemoveExportPresetMutation,
  AppAdminCustomerExportPresetViewRemoveExportPresetMutationVariables,
} from './__generated__/AppAdminCustomerExportPresetViewRemoveExportPresetMutation';

@Component({
  apollo: {
    customer: {
      query,
      fetchPolicy: 'cache-and-network',
      variables(this: ExportPresetView): AppAdminCustomerExportPresetViewQueryVariables {
        return { customerId: this.customerId, id: this.exportPresetId };
      },
    },
  },
  data() {
    return { customer: undefined };
  },
})
export default class ExportPresetView extends Vue {
  @StringProp(true)
  private readonly customerId!: string;

  @StringProp(true)
  private readonly exportPresetId!: string;

  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  private customer?: AppAdminCustomerExportPresetViewQuery['customer'];

  private get exportPreset():
    | AppAdminCustomerExportPresetViewQuery['customer']['first']['exportPresets']['first']
    | undefined {
    return this.customer?.first.exportPresets.first;
  }

  private get timeframeOptions(): Option[] {
    return Object.values(TIMEFRAME_META);
  }

  private get exportPresetTypes(): Option[] {
    return Object.values(EXPORT_PRESET_TYPES_META);
  }

  private async editExportPreset(input: EditExportPresetInput): Promise<void> {
    const { data } = await this.$apollo.mutate<
      AppAdminCustomerExportPresetViewEditExportPresetMutation,
      AppAdminCustomerExportPresetViewEditExportPresetMutationVariables
    >({
      mutation: editExportPresetMutation,
      variables: { input: { ...input, id: this.exportPresetId } },
    });

    if (!data) {
      throw new Error('Die Exportvorlage konnte nicht gespeichert werden.');
    }

    this.ADD_TOAST_MESSAGES({
      messages: [{ text: 'Exportvorlage gespeichert!', class: 'success' }],
    });
  }

  private async removeExportPreset(): Promise<void> {
    try {
      if (!window.confirm(`Sind Sie sich sicher, dass Sie diese Exportvorlage löschen möchten?`)) {
        return;
      }

      const { data } = await this.$apollo.mutate<
        AppAdminCustomerExportPresetViewRemoveExportPresetMutation,
        AppAdminCustomerExportPresetViewRemoveExportPresetMutationVariables
      >({
        mutation: removeExportPresetMutation,
        variables: { input: { id: this.exportPresetId } },
      });

      if (!data) {
        throw new Error('Die Exportvorlage konnte nicht gelöscht werden.');
      }
    } catch (error) {
      if (String(error).includes('foreign key constraint')) {
        throw new Error(
          'Die Exportvorlage wird in einem oder mehreren Exporten verwendet und kann nicht gelöscht werden!',
        );
      }

      throw error;
    }
  }

  private async onExportPresetRemoved(): Promise<void> {
    this.ADD_TOAST_MESSAGES({
      messages: [{ text: 'Exportvorlage gelöscht!', class: 'success' }],
    });

    this.$router.back();
  }
}
