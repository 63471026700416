




























































import { ObjectProp, FunctionProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import { AppAdminContractEditPanelFragment } from './__generated__/AppAdminContractEditPanelFragment';
import { EditContractAction } from './model';

@Component
export default class ContractEditPanel extends Vue {
  @ObjectProp(true)
  private readonly contract!: AppAdminContractEditPanelFragment;

  @FunctionProp(() => Promise.resolve())
  private readonly action!: EditContractAction;

  private readonly offerOptions = ['Gebäudekonnektivität 4.0'];

  private readonly salesPartnerOptions = [
    'Unitymedia BW GmbH',
    'Unitymedia Hessen GmbH',
    'Unitymedia NRW GmbH',
    'Vodafone Kabel Deutschland AG',
  ];

  private toNumber(value: unknown): number | null {
    return value === null ? null : Number(value);
  }
}
