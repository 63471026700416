var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-layout',{attrs:{"use":"core-layout-default"},scopedSlots:_vm._u([{key:"default",fn:function(){return [(_vm.attributeDefinitionTemplates)?_c('ui-flex',[_c('ui-cell',{attrs:{"basis":1}},[_c('ui-content-header',{attrs:{"headline":_vm.attributeDefinitionTemplates.first.configuration.name,"back-link":""},on:{"back-click":function($event){return _vm.$router.go(-1)}}},[_c('ui-breadcrumb-item',{attrs:{"to":{ name: 'AppAdmin' }}},[_vm._v("Administration")]),_vm._v(_vm._s(' ')),_c('ui-breadcrumb-item',{attrs:{"to":{ name: 'AppAdmin/AttributeDefinitionTemplates' }}},[_vm._v("Attributvorlagen")])],1)],1),_c('ui-cell',{attrs:{"basis":1}},[_c('ui-panel',[_c('ui-flex',[_c('ui-cell',{attrs:{"grow":0,"push-right":""}},[_c('ui-wizard',{attrs:{"title":"Attribute für Kunden von Vorlage erzeugen"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var show = ref.show;
return [_c('ui-wizard-button',{on:{"click":show}},[_vm._v("Attribute für Kunden erzeugen")])]}},{key:"content",fn:function(ref){
var hide = ref.hide;
return [_c('ui-form',{attrs:{"action":_vm.addTemplateBasedAttributeDefinitions},on:{"submitted":function($event){return _vm.onAttributeDefinitionsAdded($event.result, hide)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var dirty = ref.dirty;
var busy = ref.busy;
var submit = ref.submit;
var errorMessages = ref.errorMessages;
return [_c('core-toast',{attrs:{"messages":errorMessages,"error":""}}),_c('ui-flex',[_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-field',{attrs:{"input":"ui-input-checkbox-list","options":_vm.customerOptions,"path":"customerIds","label":"Kunden"}})],1),_c('ui-cell',{attrs:{"grow":0,"push-right":""}},[_c('ui-wizard-button',{attrs:{"disabled":!dirty,"busy":busy},on:{"click":submit}},[_vm._v("Erzeugen")])],1)],1)]}}],null,true)})]}}],null,false,4033259906)})],1),_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form',{attrs:{"initial-data":_vm.attributeDefinitionTemplates.first,"action":_vm.editAttributeDefinitionTemplate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var dirty = ref.dirty;
var busy = ref.busy;
var submit = ref.submit;
var errorMessages = ref.errorMessages;
return [_c('core-toast',{attrs:{"messages":errorMessages,"error":""}}),_c('ui-flex',[_c('ui-cell',{attrs:{"basis":0.5}},[_c('ui-form-field',{attrs:{"input":"ui-input-text","initial-path":"configuration.name","path":"name","label":"Name"}})],1),_c('ui-cell',{attrs:{"basis":0.5}},[_c('ui-form-field',{attrs:{"input":"ui-input-select","initial-path":"configuration.__typename","label":"Typ","options":_vm.attributeTypeOptions,"reduce":function (ref) {
	var value = ref.value;

	return value;
},"disabled":""}})],1),_c('ui-cell',{attrs:{"basis":0.5}},[_c('ui-form-field',{attrs:{"input":"ui-input-checkbox","path":"addAttributeOnCustomerAdded","option-label":"Attribut für neue Kunden automatisch anlegen","label":' ',"description":"Wenn aktiv, wird beim Anlegen eines neuen Kunden automatisch ein Attribut erzeugt das dieser Vorlage entspricht."}})],1),_c('ui-cell',{attrs:{"basis":0.5}},[_c('ui-form-field',{attrs:{"input":"ui-input-textarea","initial-path":"configuration.description","path":"description","label":"Beschreibung"}})],1),_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-field',{attrs:{"input":"ui-input-checkbox-list","initial-path":"configuration.treeNodeTypes","path":"treeNodeTypes","label":"Anwendbar auf","all":"","empty-value":_vm.emptyArray,"options":_vm.treeNodeTypeOptions}})],1),(_vm.attributeDefinitionTemplates.first.configuration.__typename === 'SelectAttributeConfiguration')?_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-field',{attrs:{"input":"ui-input-select","initial-path":"configuration.options","path":"options","label":"Optionen","taggable":"","multiple":"","no-drop":"","placeholder":"Optionen eingeben (Drücken Sie \"ENTER\" um die Eingabe als Option hinzuzufügen)"}})],1):_vm._e(),_c('ui-cell',{attrs:{"basis":1}},[_c('ui-flex',[_c('ui-cell',{attrs:{"grow":0}},[_c('ui-form',{attrs:{"action":_vm.removeAttributeDefinitionTemplate,"no-reset":""},on:{"submitted":function($event){return _vm.onAttributeDefinitionTemplateRemoved()}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var busy = ref.busy;
var submit = ref.submit;
var errorMessages = ref.errorMessages;
return [_c('core-toast',{attrs:{"messages":errorMessages,"error":""}}),_c('ui-wizard-button',{attrs:{"secondary":"","busy":busy},on:{"click":submit}},[_vm._v("Vorlage löschen")])]}}],null,true)})],1),_c('ui-cell',{attrs:{"grow":0,"push-right":""}},[_c('ui-wizard-button',{attrs:{"disabled":!dirty,"busy":busy},on:{"click":submit}},[_vm._v("Speichern")])],1)],1)],1)],1)]}}],null,false,3011546640)})],1)],1)],1)],1)],1):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }