import { PropertySubdivisionVisualizationHint, TreeNodeType } from '@/types/iot-portal';
import { cloneDeep } from 'lodash';
import { NodeListNode, NodeListNodeType } from '../components/node-list/model';
import {
  NodeTreeChangesManagerAddTreeNodeInput,
  NodeTreeChangesManagerEditAfterAddPropertyInput,
  NodeTreeChangesManagerEditAfterAddPropertySubdivisionInput,
} from '../node-tree-changes-manager/model';
import NodeTreeChangesManager from '../node-tree-changes-manager/NodeTreeChangesManager';

export function getNodeListWithEditChanges(
  nodeList: NodeListNode[],
  changesManager: NodeTreeChangesManager,
): NodeListNode[] {
  return nodeList.map((node) => {
    const change = changesManager.get(node.id);
    if (change && change.type === 'Edit') {
      return Object.assign(node, change.input.input);
    }
    return node;
  });
}

export function getNodeListWithRemoveChanges(
  nodeList: NodeListNode[],
  changesManager: NodeTreeChangesManager,
): NodeListNode[] {
  return nodeList.filter((node) => {
    const change = changesManager.get(node.id);
    return !(change && change.type === 'Remove');
  });
}

export function convertAddChangesToNodeList(
  changes: NodeTreeChangesManagerAddTreeNodeInput[],
  changesManager: NodeTreeChangesManager,
): NodeListNode[] {
  return changes
    .filter((change) =>
      [TreeNodeType.Property, TreeNodeType.Directory, TreeNodeType.PropertyGroup].includes(change.treeNode.type),
    )
    .map((change) => {
      const childChanges = changesManager.getAddChangesByParentId(change.temporaryId);
      return {
        id: change.temporaryId,
        name: change.treeNode.editAfterAdd?.input.name ?? change.treeNode.name,
        order: change.treeNode.order ?? 0,
        type: change.treeNode.type as NodeListNodeType,
        floorsCount:
          change.treeNode.type === 'Property'
            ? (change.treeNode.editAfterAdd &&
                (change.treeNode.editAfterAdd as NodeTreeChangesManagerEditAfterAddPropertyInput).input.floors
                  ?.length) ??
              0
            : undefined,
        roomsAndApartmentsCount:
          change.treeNode.type === 'Property'
            ? childChanges?.filter(
                (child) =>
                  child.input.treeNode.editAfterAdd &&
                  (child.input.treeNode.editAfterAdd as NodeTreeChangesManagerEditAfterAddPropertySubdivisionInput)
                    .input.visualizationHint !== PropertySubdivisionVisualizationHint.STAIRWELL,
              ).length ?? 0
            : undefined,
        propertiesCount:
          change.treeNode.type === 'Directory' || change.treeNode.type === 'PropertyGroup'
            ? childChanges?.filter((child) => child.input.treeNode.type === 'Property').length ?? 0
            : undefined,
        open: false,
        children: [],
      };
    });
}

export function getNodeListWithAddChanges(
  nodeList: NodeListNode[],
  parentId: string,
  changesManager: NodeTreeChangesManager,
): NodeListNode[] {
  const addedNodes = convertAddChangesToNodeList(
    changesManager.getAddChangesByParentId(parentId).map((change) => change.input),
    changesManager,
  );
  return nodeList.concat(addedNodes);
}

export function getNodeListWithChanges(
  currentNodeList: NodeListNode[],
  originalNodeList: NodeListNode[],
  parentId: string,
  changesManager: NodeTreeChangesManager,
): NodeListNode[] {
  let newNodeList = cloneDeep(originalNodeList);

  newNodeList = getNodeListWithEditChanges(newNodeList, changesManager);
  newNodeList = getNodeListWithRemoveChanges(newNodeList, changesManager);

  newNodeList = getNodeListWithAddChanges(newNodeList, parentId, changesManager);

  for (const node of newNodeList) {
    node.children = getNodeListWithChanges(
      currentNodeList.find((currentNode) => currentNode.id === node.id)?.children ?? [],
      node.children ?? [],
      node.id,
      changesManager,
    );
  }

  for (const node of currentNodeList) {
    const newNode = newNodeList.find((originalNode) => originalNode.id === node.id);
    if (newNode) {
      newNode.open = node.open;
    }
  }

  return newNodeList;
}
