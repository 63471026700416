


























import UiButton from '@/components/clickables/Button.global.vue';
import { Component, Vue } from 'vue-property-decorator';
import { RequiredProp } from '@/util/prop-decorators';
import { AppAdminCustomerManagePropertiesNodeEditorPropertySubdivisionRoomsPropertyPropertySubdivisionFragment } from './__generated__/AppAdminCustomerManagePropertiesNodeEditorPropertySubdivisionRoomsPropertyPropertySubdivisionFragment';
import roomIcon from '@/features/app-admin/assets/room.svg';
import close from '@/assets/images/close.svg';
import EditableValue from '@/components/editable-value/EditableValue.global.vue';

type PropertySubdivision =
  AppAdminCustomerManagePropertiesNodeEditorPropertySubdivisionRoomsPropertyPropertySubdivisionFragment;

@Component({
  components: { UiButton, EditableValue },
})
export default class PropertySubdivisionRoomsModal extends Vue {
  @RequiredProp()
  private propertySubdivision!: PropertySubdivision;

  private roomIcon = roomIcon;
  private close = close;

  private get title(): string {
    return `Edit Rooms for ${this.propertySubdivision.name}`;
  }

  private addRoom(): void {
    this.propertySubdivision.rooms.push({
      name: 'Neuer Raum',
      __typename: 'PropertySubdivisionRoom',
    });
    this.$emit('edit');
  }

  private removeRoom(room: PropertySubdivision['rooms'][number]): void {
    this.propertySubdivision.rooms.splice(this.propertySubdivision.rooms.indexOf(room), 1);
    this.$emit('edit');
  }

  private onRoomEdit(): void {
    this.$emit('edit');
  }
}
