


























import { isString } from '@/util/lang';
import { Component, Vue } from 'vue-property-decorator';
import addGroupMutation from './add-group.gql';
import {
  AppAdminAddGroupWizardControlMutation,
  AppAdminAddGroupWizardControlMutationVariables,
} from './__generated__/AppAdminAddGroupWizardControlMutation';

type FormData = { name: string };

type Group = AppAdminAddGroupWizardControlMutation['addGroup']['group'];

@Component
export default class AddGroupWizardControl extends Vue {
  private async addGroup(input: FormData): Promise<Group> {
    const { data } = await this.$apollo.mutate<
      AppAdminAddGroupWizardControlMutation,
      AppAdminAddGroupWizardControlMutationVariables
    >({
      mutation: addGroupMutation,
      variables: {
        input,
      },
    });

    if (!data) {
      throw new Error('Die uVI Codes konnte nicht angelegt werden!');
    }

    return data.addGroup.group;
  }

  private isNonEmptyString(value: unknown): boolean {
    return isString(value) && value.length > 0;
  }

  private isValidData({ name }: Partial<FormData>): boolean {
    return this.isNonEmptyString(name);
  }

  private async onSubmitted(group: Group, hide: () => Promise<void>): Promise<void> {
    await hide();

    this.$emit('group-added', group);
  }
}
