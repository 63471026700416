









import Vue from 'vue';
import { BooleanProp, RequiredProp, StringProp } from '@/util/prop-decorators';
import { Component } from 'vue-property-decorator';
import { Component as ComponentType } from 'vue';
import DirectoryEditor from './directory/DirectoryEditor.vue';
import PropertyGroupEditor from './property-group/PropertyGroupEditor.vue';
import PropertyEditor from './property/PropertyEditor.vue';
import { NodeEditorNode } from './model';

@Component
export default class NodeEditorContainer extends Vue {
  @RequiredProp()
  private readonly node!: NodeEditorNode;

  @StringProp(true)
  private readonly customerId!: string;

  @BooleanProp(true)
  private readonly canEditContract!: boolean;

  private get title(): string {
    return `Edit ${this.node.name}`;
  }

  private get component(): ComponentType {
    switch (this.node.__typename) {
      case 'Directory':
        return DirectoryEditor;
      case 'PropertyGroup':
        return PropertyGroupEditor;
      case 'Property':
        return PropertyEditor;
    }
  }
}
