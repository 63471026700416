
















import eventBus from '@/util/event-bus';
import { ArrayProp, BooleanProp, NumberProp, RequiredProp } from '@/util/prop-decorators';
import { Component, Inject, Provide, Vue } from 'vue-property-decorator';
import DirectoryNode from './DirectoryNode.vue';
import { NodeListEditNodeOrder, NodeListNode, NODE_LIST_EDIT_NODE_ORDER, NODE_LIST_IMPORT_TO_DIRECTORY } from './model';
import PropertyGroupNode from './PropertyGroupNode.vue';
import PropertyNode from './PropertyNode.vue';

@Component({
  data() {
    return {
      dropIndex: undefined,
      dragIndex: undefined,
    };
  },
})
export default class NodeList extends Vue {
  @ArrayProp(true)
  private nodes!: NodeListNode[];

  @RequiredProp()
  protected parent!: NodeListNode | undefined;

  @BooleanProp()
  private indent!: boolean;

  @NumberProp()
  private level!: number;

  private dragIndex?: number;
  private dropIndex?: number;

  private componentFor({ type }: NodeListNode): unknown {
    return type === 'Directory' ? DirectoryNode : type === 'PropertyGroup' ? PropertyGroupNode : PropertyNode;
  }

  private get sortedNodes(): NodeListNode[] {
    return this.nodes.sort((a, b) => {
      return (
        a.order - b.order ||
        (a.type !== 'Directory' ? 1 : 0) - (b.type !== 'Directory' ? 1 : 0) ||
        a.name.localeCompare(b.name)
      );
    });
  }

  @Provide(NODE_LIST_IMPORT_TO_DIRECTORY)
  public nodeListImportToDirectory(directory: NodeListNode): void {
    eventBus.$emit('nodeList:import', directory);
  }

  @Inject(NODE_LIST_EDIT_NODE_ORDER)
  private editNodeOrder!: NodeListEditNodeOrder;

  private onDragStart(event: DragEvent, node: NodeListNode, index: number): void {
    node.open = false;
    this.dragIndex = index;
    event.stopPropagation();
  }

  private onDragOver(event: DragEvent): void {
    if (this.dragIndex !== undefined) {
      event.preventDefault();
    }
  }

  private onDragEnter(event: DragEvent, index: number): void {
    if (this.dragIndex === undefined) {
      return;
    }
    this.dropIndex = event.offsetY < (event.currentTarget as HTMLElement).offsetHeight / 2 ? index : index + 1;
  }

  private async dropNode(event: DragEvent): Promise<void> {
    event.preventDefault();
    event.stopPropagation();
    if (this.dragIndex === undefined || this.dropIndex === undefined || this.dragIndex === this.dropIndex) {
      return;
    }
    const sortedNodes = this.sortedNodes;
    const tempNode = sortedNodes[this.dragIndex];
    sortedNodes.splice(this.dragIndex, 1);
    sortedNodes.splice(this.dragIndex < this.dropIndex ? this.dropIndex - 1 : this.dropIndex, 0, tempNode);

    sortedNodes.map((node, index) => this.editNodeOrder(node, index));

    this.dropIndex = undefined;
    this.dragIndex = undefined;
  }

  private onMouseLeave(): void {
    this.dropIndex = undefined;
  }
}
