var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-table',{attrs:{"columns":_vm.columns,"rows":_vm.attributeDefinitionTemplates},scopedSlots:_vm._u([{key:"type",fn:function(ref){
var value = ref.value;
return [_c('domain-ui-attribute-configuration-type',{attrs:{"type":value}})]}},{key:"treeNodeTypes",fn:function(ref){
var value = ref.value;
return [_c('ui-inline-list',{attrs:{"items":value,"comparator":_vm.comparator},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('domain-ui-tree-node-type',{attrs:{"type":item,"inline":"","plural":""}})]}}],null,true)})]}},{key:"addAttributeOnCustomerAdded",fn:function(ref){
var row = ref.row;
return [(row.addAttributeOnCustomerAdded)?_c('ui-tooltip',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_vm._v("Ja")]},proxy:true},{key:"content",fn:function(){return [_vm._v("Beim Anlegen eines neuen Kunden wird automatisch ein Attribut erzeugt das dieser Vorlage entspricht.")]},proxy:true}],null,true)}):_c('span',[_vm._v("Nein")])]}},{key:"actions",fn:function(ref){
var row = ref.row;
return [_c('ui-link',{on:{"click":function($event){return _vm.$emit('edit-click', row)}}},[_vm._v("Bearbeiten")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }