var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.users)?_c('ui-panel',[_c('ui-form',{attrs:{"initialData":_vm.users.first,"action":_vm.editUser},on:{"submitted":function($event){return _vm.$emit('user-changed')}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var dirty = ref.dirty;
var busy = ref.busy;
var submit = ref.submit;
return [_c('ui-flex',[_c('ui-cell',{attrs:{"basis":0.5}},[_c('ui-form-field',{attrs:{"input":"ui-input-text","path":"editUserInput.firstName","initial-path":"firstName","label":"Vorname"}})],1),_c('ui-cell',{attrs:{"basis":0.5}},[_c('ui-form-field',{attrs:{"input":"ui-input-text","path":"editUserInput.lastName","initial-path":"lastName","label":"Nachname"}})],1),_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-field',{attrs:{"input":"ui-input-text","path":"editPhoneNumberInput.phoneNumber","initial-path":"phoneNumber","label":"Telefonnummer"}})],1),_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-field',{attrs:{"input":"ui-input-checkbox","initial-path":"admin","option-label":"Administrator","disabled":""}}),_c('ui-form-field',{attrs:{"input":"ui-input-checkbox","initial-path":"customerAdmin","path":"editUserCustomerAdminRoleInput.customerAdmin","option-label":"Kunden Admin"}}),_c('ui-form-field',{attrs:{"input":"ui-input-checkbox","path":"editUserTechnicianRole.technician","initial-path":"technician","option-label":"Technician"}}),_c('ui-form-field',{attrs:{"input":"ui-input-checkbox","initial-path":"mfa","path":"configureUserOTPInput.mfa","true-value":_vm.MFA_STATE.ACTIVE,"false-value":_vm.MFA_STATE.INACTIVE,"option-label":"MFA"}})],1),_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-field',{attrs:{"input":"ui-input-select","initial-path":"groups.items","path":"setUserGroupsInput.groupIds","label":"Weisen Sie eine Rollengruppe zu","options":_vm.groupOptions,"reduce":function (ref) {
	var value = ref.value;

	return value;
},"initial-transformer":_vm.pluckIds,"multiple":"","placeholder":"Hinzufügen…"}})],1),_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-field',{attrs:{"input":"ui-input-select","label":"Zugriff auf ausgewählten Hersteller einschränken","initial-path":"restrictedPermissions.manufacturers","path":"setRestrictedDeviceManufacturerPermissionsForUserInput.manufacturers","options":_vm.manufacturersOptions,"reduce":function (ref) {
	var value = ref.value;

	return value;
},"multiple":"","placeholder":"Hinzufügen…"}})],1),_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-field',{attrs:{"input":"ui-input-select","label":"Zugriff auf ausgewählte Gerätetypen einschränken","initial-path":"restrictedPermissions.deviceRoles","path":"setRestrictedDeviceManufacturerPermissionsForUserInput.deviceRoles","options":_vm.deviceRoles,"reduce":function (ref) {
	var value = ref.value;

	return value;
},"multiple":"","placeholder":"Hinzufügen…"}})],1),_c('ui-cell',{attrs:{"grow":0,"push-right":""}},[_c('ui-wizard-button',{attrs:{"disabled":!dirty,"busy":busy},on:{"click":submit}},[_vm._v("Speichern")])],1)],1)]}}],null,false,2794449641)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }